<template>
  <div class="content-md margin-auto">
    <v-card v-if="localValue?.wizardParameters.isDefault" class="mb-4">
      <v-card-text class="d-flex flex-column align-center pa-16">
        <v-icon class="mb-3" size="80" icon="$endpointDataGovernance" />
        <span
          class="headline6 mb-3"
          v-text="$t(`userDataGovernanceSettings.monitoringTab.dataPrivacyTitle`)"
        ></span>
        <span
          class="text-center body1 mb-3"
          v-html="$t(`userDataGovernanceSettings.monitoringTab.dataPrivacySubtitle`)"
        ></span>
        <v-btn
          class="mt-4"
          color="primary"
          rounded
          data-testid="user-data-page-set-params-btn"
          @click="onSetParametersClick"
          :text="$t(`userDataGovernanceSettings.monitoringTab.dataPrivacyCta`)"
        />
      </v-card-text>
    </v-card>
    <v-skeleton-loader
      class="skeleton-loader--security-settings"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS_DENSE"
    >
      <div v-if="!localValue?.wizardParameters.isDefault" class="d-flex justify-space-between mb-4">
        <v-card class="sensitive-data-wrapper mr-4 pa-2 pt-3" v-if="localValue">
          <v-card-text>
            <div class="subtitle1">
              {{ $t("userDataGovernanceSettings.monitoringTab.privacySensitiveData") }}
            </div>
            <div
              class="mb-4"
              v-html="$t('userDataGovernanceSettings.monitoringTab.sensitiveDataSubtitle')"
            />
            <div class="subtitle1 mb-2">
              {{ $t("userDataGovernanceSettings.monitoringTab.recommendedDataTypes") }}
            </div>
            <v-checkbox
              v-for="recommendedDataType in recommendedDataTypes"
              :key="recommendedDataType"
              :disabled="actionNotAllowed"
              v-model="localValue[recommendedDataType as keyof UserDataGovernanceSettings]"
              :label="$t(`userDataGovernanceSettings.monitoringTab.${recommendedDataType}`)"
            />
            <div class="subtitle1 mb-2">
              {{ $t("userDataGovernanceSettings.monitoringTab.otherDataTypes") }}
            </div>

            <span v-if="otherDataTypes.length === 0">
              {{ $t("userDataGovernanceSettings.monitoringTab.noOtherDataTypes") }}
            </span>

            <v-checkbox
              v-for="otherDataType in otherDataTypes"
              :key="otherDataType"
              :disabled="actionNotAllowed"
              v-model="localValue[otherDataType as keyof UserDataGovernanceSettings]"
              :label="$t(`userDataGovernanceSettings.monitoringTab.${otherDataType}`)"
            />
          </v-card-text>
        </v-card>
        <v-card class="w-25">
          <v-card-text>
            <div class="d-flex justify-space-between align-center mb-4">
              <div class="subtitle1">
                {{ $t("userDataGovernanceSettings.monitoringTab.selectedParameters.title") }}
              </div>
              <span
                class="coro-link"
                data-testid="user-data-page-edit-industry-btn"
                @click="onSetParametersClick"
              >
                {{ $t("general.edit") }}
              </span>
            </div>
            <div class="mb-6">
              <div class="subtitle1 mb-2">
                {{ $t("userDataGovernanceSettings.monitoringTab.selectedParameters.industries") }}
              </div>
              <div
                v-for="industry in localValue?.wizardParameters.industries.slice().sort()"
                :key="industry"
              >
                <span>{{ $t(`modals.setDataGovernanceIndustry.industries.${industry}`) }}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <v-card v-if="localValue" data-testid="user-data-page-settings">
        <v-card-text>
          <div class="custom-data-wrapper">
            <div class="subtitle1 mb-4">
              {{ $t("userDataGovernanceSettings.monitoringTab.securityAndBusinessData") }}
            </div>
            <v-checkbox
              v-model="localValue.customData.monitorPasswords"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-passwords-checkbox"
              :ripple="false"
              :label="$t('userDataGovernanceSettings.monitoringTab.passwords')"
              class="mt-3 mb-1"
            />
            <v-checkbox
              v-model="localValue.customData.monitorCertificates"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-certificates-checkbox"
              :ripple="false"
              :label="$t('userDataGovernanceSettings.monitoringTab.certificates')"
              class="mt-3 mb-1"
            />
            <v-checkbox
              v-model="localValue.customData.monitorSourceCodes"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-source-codes-checkbox"
              :ripple="false"
              :label="$t('userDataGovernanceSettings.monitoringTab.sourceCode')"
              class="mt-3 mb-1"
            />
            <v-checkbox
              v-model="localValue.customData.monitorSensitiveKeywords"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-sensitive-keywords-checkbox"
              :ripple="false"
              :label="
                $t('userDataGovernanceSettings.monitoringTab.dataObjectsWithSpecificKeywords')
              "
              class="mt-3 mb-1"
            />
            <v-combobox
              v-model="localValue.customData.sensitiveKeywords"
              :disabled="actionNotAllowed || !localValue!.customData.monitorSensitiveKeywords"
              data-testid="custom-data-monitor-keywords-combobox"
              variant="outlined"
              multiple
              item-color="red"
              class="ml-7"
              :rules="sensitiveKeywordsRule"
              :placeholder="
                $t('userDataGovernanceSettings.monitoringTab.specificKeywordsPlaceholder')
              "
              @update:search="onKeywordInput('sensitiveKeywords')"
            >
              <template v-slot:chip="{ item, props }">
                <v-chip
                  v-bind="props"
                  :disabled="actionNotAllowed"
                  :data-testid="`chip-${item}`"
                  close-icon="$closeCircle"
                  :closable="true"
                  variant="flat"
                  color="indigo-faint"
                >
                  <div class="d-flex align-center justify-space-between">
                    <span class="ml-1 mr-2 chip-content__text">{{ item.value }}</span>
                  </div>
                </v-chip>
              </template>
              <template #item="data">
                <v-list-item>
                  <span class="ml-1">{{ data.item }}</span>
                </v-list-item>
              </template>
            </v-combobox>
            <v-checkbox
              v-model="localValue.customData.monitorSensitiveFileTypes"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-sensitive-file-types-checkbox"
              :ripple="false"
              :label="$t('userDataGovernanceSettings.monitoringTab.specificFileTypesTitle')"
              class="mt-3 mb-1"
            />
            <v-combobox
              v-model="localValue.customData.sensitiveFileTypes"
              :disabled="actionNotAllowed || !localValue!.customData.monitorSensitiveFileTypes"
              data-testid="custom-data-monitor-file-types-combobox"
              variant="outlined"
              multiple
              class="ml-7"
              :placeholder="
                $t('userDataGovernanceSettings.monitoringTab.specificFileTypesPlaceholder')
              "
              @update:search="onKeywordInput('sensitiveFileTypes')"
            >
              <template v-slot:chip="{ item, props }">
                <v-chip
                  v-bind="props"
                  :disabled="actionNotAllowed"
                  :data-testid="`chip-${item}`"
                  close-icon="$closeCircle"
                  :closable="true"
                  variant="flat"
                  color="indigo-faint"
                >
                  <div class="d-flex align-center justify-space-between">
                    <span class="ml-1 mr-2 chip-content__text">{{ item.value }}</span>
                  </div>
                </v-chip>
              </template>
              <template #item="data">
                <v-list-item>
                  <span class="ml-1">{{ data.item }}</span>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
        </v-card-text>
      </v-card>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, type WritableComputedRef } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import {
  type UserDataGovernanceSettings,
  useUserDataGovernanceModule,
} from "@/_store/user-data-governance/user-data-governance.module";
import { storeToRefs } from "pinia";
import { SubscriptionModule } from "@/constants/workplaces";
import {
  componentDialogsConfigConstructor,
  hasSeparator,
  isWorkspaceFrozenOrActionRestricted,
  useTwoWayBinding,
} from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import { useI18n } from "vue-i18n";
import { uniq } from "lodash";
import { UserDataGovernanceAction } from "@/constants/user-data-governance";
import { ModalWidth } from "@/constants/modals";
import SetIndustryModal from "@/components/modals/data-governance/SetIndustryModal.vue";
import { Locations } from "@/constants/data-governance";
export default defineComponent({
  setup() {
    const userDataStore = useUserDataGovernanceModule();
    const { settings, recommendedDataTypes, otherDataTypes, showSkeletonLoader } =
      storeToRefs(userDataStore);
    const i18n = useI18n();
    const { openDialog } = useDialogsStore();

    onMounted(async () => {
      await userDataStore.getData();
    });

    const actionNotAllowed = isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.USER_DATA_GOVERNANCE
    );

    const sensitiveKeywordsRule = [
      (keywords: string[]) => {
        if (keywords?.length > 200) {
          return i18n.t("validations.maximumAllowedKeywords");
        }
        return true;
      },
    ];

    const localValue: WritableComputedRef<UserDataGovernanceSettings> = useTwoWayBinding(
      settings,
      userDataStore.updateSettings
    );

    const onKeywordInput = (arrayKey: "sensitiveKeywords" | "sensitiveFileTypes") => {
      const previousValues = localValue.value!.customData[arrayKey];
      const lastKeyword = previousValues?.at(-1);
      const hasCommaSeparator = hasSeparator(lastKeyword!);

      if (hasCommaSeparator) {
        const split = lastKeyword!.split(",");

        localValue.value!.customData[arrayKey] = uniq(
          [...localValue.value!.customData[arrayKey].slice(0, -1), ...split].map((item) =>
            item.toLowerCase().trim()
          )
        );
      }
    };

    const onSetParametersClick = () => {
      const dialogConfig = {
        ...componentDialogsConfigConstructor({
          action: UserDataGovernanceAction.SET_INDUSTRY,
          component: SetIndustryModal,
          width: ModalWidth.LARGE,
          disable: actionNotAllowed,
          item: localValue.value?.wizardParameters.isDefault
            ? { selectedIndustries: [], selectedLocations: [] }
            : localValue.value?.wizardParameters,
          callback: async (val: { selectedIndustries: string[] }) => {
            await userDataStore.updatePrivacyConfig(val.selectedIndustries);
          },
        }),
      };

      openDialog(dialogConfig);
    };

    onUnmounted(() => {
      userDataStore.$reset();
    });

    return {
      coronetSkeletonLoaderTypes,
      settings,
      showSkeletonLoader,
      localValue,
      actionNotAllowed,
      sensitiveKeywordsRule,
      onKeywordInput,
      onSetParametersClick,
      Locations,
      recommendedDataTypes,
      otherDataTypes,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-field__input {
    opacity: 1;
  }

  .v-field .v-chip {
    --v-chip-height: 32px;
  }
}
.sensitive-data-wrapper {
  flex-grow: 1;
}
.chip-item {
  height: 36px;
  border-radius: 18px;
}

.category-icon {
  font-size: 40px;
}

.chip-content__text {
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
