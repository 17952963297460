<template>
  <v-form ref="form" v-model="formValid" lazy-validation>
    <div class="body1 mb-4" v-html="$t('modals.setupVirtualOffice.description')"></div>
    <div class="info-block mb-6 d-flex align-center">
      <div class="body2">
        {{ $t("modals.setupVirtualOffice.selectedRegion") }}
        {{ config.item.region.displayValue }}
      </div>
    </div>
    <div class="subtitle1 mb-4">
      {{ $t("modals.setupVirtualOffice.confirmInputLabel") }}
    </div>
    <v-text-field
      v-model="localValue.approveText"
      class="mt-6"
      outlined
      :rules="matchApproveRules"
    ></v-text-field>
  </v-form>
</template>

<script lang="ts">
import cloneDeep from "lodash/cloneDeep";
import { defineComponent, type PropType, ref, watch } from "vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { VuetifyFormRef } from "@/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<
        DialogDataConfig<{ region: { value: string; displayValue: string } }>
      >,
      required: true,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const localValue = ref({ ...cloneDeep(props.config), approveText: "" });
    const form = ref<VuetifyFormRef>();
    const i18n = useI18n();
    const formValid = ref(true);

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );

    watch(formValid, (value) => {
      if (value === null) {
        return;
      }

      emit("update:valid", value);
    });

    const validate = async () => {
      const validationResult = await form.value?.validate();

      return validationResult?.valid;
    };

    return {
      formValid,
      localValue,
      form,
      validate,
      matchApproveRules: [
        (v?: string) => {
          if (!v || !v.length) {
            return i18n.t("validations.typeApprove.required");
          }
          if (v !== i18n.t("validations.typeApprove.approve").toUpperCase()) {
            return i18n.t("validations.typeApprove.exactMatch");
          }
          return true;
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.info-block {
  padding: 2px 16px;
}
</style>
