import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { TicketTrigger } from "@/constants/tickets";
import { convertTimeFrameToUnix } from "@/_helpers/utils";
import type { TimeFrame } from "@/types";
import type { SubscriptionModule } from "@/constants/workplaces";
import moment from "moment/moment";
import type { OsType } from "@/constants/devices.ts";

export interface ExecutiveSummaryReportData {
  users: {
    total: number;
    protected: number;
    unProtected: number;
  };
  devices: {
    total: number;
    totalOffline?: number;
    types: { [osType in OsType]: number };
    outdatedDevices?: { [osType in OsType]: number };
  };
  tickets: {
    total: number;
    processed: number;
    unProcessed: number;
  };
  topVulnerabilities: {
    [key in TicketTrigger]: number;
  };
  protection: ExecutiveSummaryReportProtectionSettings;
  [SubscriptionModule.SECURITY_AWARENESS]: ExecutiveSummarySecurityAwarenessReportData;
}

export interface DnsSummaryReportData {
  totalQueries: number;
  totalBlockedQueries: number;
  generalChartData: Array<DnsReportChartData>;
  blockedChartData: Array<DnsReportChartData>;
  topDomainsPermitted: Array<DomainData>;
  topDomainsBlocked: Array<DomainData>;
  topClients: Array<HostData>;
  topClientsBlocked: Array<HostData>;
}

export interface DnsReportChartData {
  x: string;
  y: number;
  startDate: number;
  endDate: number;
}

interface DomainData {
  domain: string;
  count: number;
}
interface HostData {
  host: string;
  count: number;
}

export enum ReportType {
  EXECUTIVE_SUMMARY = "workspace",
  MANAGED_SERVICES_SUMMARY = "soc",
  DNS_SUMMARY = "dns",
  SECURED_MESSAGES = "securedMessages",
  PHISHING_SIMULATION = "phishingSimulation",
  SECURITY_AWARENESS = "securityAwareness",
}

export interface SecurityAwarenessUserFailureRecord {
  userId: string;
  offenses: number;
}

export interface PhishingSimulationSummary {
  failed: number;
  noAction: number;
  reported: number;
}

export interface SecurityAwarenessPhishingSimulationDetails {
  emailName: string;
  receivedDate: number;
  failed: boolean;
  noAction: boolean;
  reported: boolean;
  associatedTrainingCompleted: boolean;
}

export interface SecurityAwarenessTrainingUserRecord {
  simulationsSent: number;
  failed: number;
  noAction: number;
  reported: number;
  totalTrainings: number;
  completedTrainings: number;
  failureRate: number;
  userId: string;
  phishingSimulations: SecurityAwarenessPhishingSimulationDetails[];
}

export type ExecutiveSummarySecurityAwarenessReportData = {
  emailsSent: number;
  trainingsSent: number;
  trainingsCompleted: Record<string, number>;
  topUsersFailedPhishing: SecurityAwarenessUserFailureRecord[];
  topUsersFailedTraining: SecurityAwarenessUserFailureRecord[];
};

export type ExecutiveSummaryReportProtectionSettings = {
  [moduleName in Exclude<SubscriptionModule, SubscriptionModule.SECURITY_AWARENESS>]: {
    vulnerabilities: {
      [key in TicketTrigger]: number;
    };
    violators: { [displayValue: string]: number };
  };
};

export type ManagedServicesResolvedTickets = {
  [moduleName in SubscriptionModule]: {
    total: number;
    vulnerabilities: {
      [key in TicketTrigger]: number;
    };
  };
};

export interface ManagedServicesReportData {
  totalResolvedTickets: number;
  resolvedTicketsByModules: ManagedServicesResolvedTickets;
}

export interface DnsReportReload {
  fromTime: number;
  toTime: number;
  refresh: boolean;
}

export interface SecuredMessagesReportData {
  total: number;
  protectedUsers: {
    [email: string]: number;
  };
}

export interface SecurityAwarenessSummary {
  overDue: number;
  toDo: number;
  completed: number;
}

interface SecurityAwarenessTrainingEngagementUser {
  userId: string;
  offenses: number;
}

interface SecurityAwarenessTrainingEngagementTraining {
  courseName: string;
  percentage: number;
}

export interface SecurityAwarenessTrainingEngagement {
  overdueCourses: SecurityAwarenessTrainingEngagementUser[];
  trainingStatus: SecurityAwarenessTrainingEngagementTraining[];
}

export interface SecurityAwarenessTrainingTrainingRecord {
  trainingName: string;
  trainingsSent: number;
  completed: boolean;
  overdue?: number; // days
  score: number;
}

export interface SecurityAwarenessTrainingUserRecord {
  total: number;
  completed: number;
  avgScore: number;
  userId: string;
  trainings: SecurityAwarenessTrainingTrainingRecord[];
}

export interface SecurityAwarenessReportData {
  totalSent: number;
  securityTrainingReport: SecurityAwarenessSummary;
  trainingEngagementSummary: SecurityAwarenessTrainingEngagement;
  userDetails: SecurityAwarenessTrainingUserRecord[];
}

export interface PhishingSimulationReportUserRecord {
  userId: string;
  simulationsSent: number;
  clicked: number;
  noAction: number;
  reported: number;
  phishingTrainingsCompleted: number;
  totalTrainings: number;
  failedPercentage: number;
  phishingSimulations: [
    {
      emailName: string;
      receivedDate: number; // timestamp
      failed: boolean;
      noAction: boolean;
      reported: boolean;
      associatedTrainingCompleted: boolean;
    },
  ];
}

interface PhishingSimulationReportHistoricRecord {
  month: number;
  year: number;
  reportedPercentage: number;
  failurePercentage: number;
  offenses: number;
  trainingsCompleted: number;
  trainingsCompletedPercentage: number;
  date: number;
}

interface FailedSimulationReportData {
  failurePercentage: number;
  simulationsSent: number;
  failed: number;
  trainingsCompleted: number;
  simulationName: string;
}

export interface PhishingSimulationReportData {
  totalSent: number;
  phishingSimulationReport: PhishingSimulationSummary;
  historic: PhishingSimulationReportHistoricRecord[];
  simulationEngagement: {
    topPhishedUsers: SecurityAwarenessTrainingEngagementUser[];
    topFailedSimulations: FailedSimulationReportData[];
  };
  userDetails: PhishingSimulationReportUserRecord[];
}

interface ReportsStoreState {
  executiveSummaryReport?: ExecutiveSummaryReportData;
  dnsSummaryReport?: DnsSummaryReportData;
  selectedTimeFrame?: TimeFrame;
  managedServicesSummaryReport?: ManagedServicesReportData;
  securedMessagesSummaryReport?: SecuredMessagesReportData;
  phishingSimulationReport: PhishingSimulationReportData;
  securityAwarenessReport: SecurityAwarenessReportData;
  loading: boolean;
  reportVersion: string;
}

const defaultPhishingSimulationReport = {
  totalSent: 0,
  phishingSimulationReport: {
    failed: 0,
    reported: 0,
    noAction: 0,
  },
  simulationEngagement: {
    topPhishedUsers: [],
    topFailedSimulations: [],
    phishingFailuresPerMonth: [],
  },
  historic: [],
  userDetails: [],
};

const defaultSecurityAwarenessReport = {
  totalSent: 0,
  securityTrainingReport: {
    overDue: 0,
    toDo: 0,
    completed: 0,
  },
  trainingEngagementSummary: {
    overdueCourses: [],
    trainingStatus: [],
  },
  userDetails: [],
};

const defaultReportsState: ReportsStoreState = {
  executiveSummaryReport: undefined,
  managedServicesSummaryReport: undefined,
  dnsSummaryReport: undefined,
  selectedTimeFrame: undefined,
  securedMessagesSummaryReport: undefined,
  securityAwarenessReport: defaultSecurityAwarenessReport,
  phishingSimulationReport: defaultPhishingSimulationReport,
  loading: false,
  reportVersion: "",
};

export const useReportsStore = defineStore("reports", {
  state: (): ReportsStoreState => ({ ...defaultReportsState }),
  actions: {
    async getExecutiveSummaryReport() {
      const { fromTime, toTime } = convertTimeFrameToUnix(this.selectedTimeFrame!);
      const request = {
        ...api.getExecutiveReport(),
        params: {
          fromTime,
          toTime,
          topVulnerabilities: 5,
        },
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);

        this.executiveSummaryReport = data;

        this.reportVersion = getReportVersion();
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    async getDnsReport(refresh = true) {
      const selectedTimeFrame = convertTimeFrameToUnix(this.selectedTimeFrame!);
      const request = {
        ...api.getDnsReport({ ...selectedTimeFrame, refresh }),
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);
        this.dnsSummaryReport = data;
        this.reportVersion = getReportVersion();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async getSecurityAwarenessReport() {
      const selectedTimeFrame = convertTimeFrameToUnix(this.selectedTimeFrame!);
      const request = {
        ...api.getSecurityAwarenessReport({ ...selectedTimeFrame }),
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);

        this.securityAwarenessReport = normalizeSecurityAwarenessReportData(data);
        this.reportVersion = getReportVersion();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async getManagedServicesSummaryReport() {
      const selectedTimeFrame = convertTimeFrameToUnix(this.selectedTimeFrame!);
      const request = {
        ...api.getManagedServicesReport(selectedTimeFrame),
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);
        this.managedServicesSummaryReport = data;
        this.reportVersion = getReportVersion();
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    async getPhishingSimulationReport() {
      const selectedTimeFrame = convertTimeFrameToUnix(this.selectedTimeFrame!);
      const request = {
        ...api.getPhishingSimulationReport({ ...selectedTimeFrame }),
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);

        this.phishingSimulationReport = normalizePhishingSimulationReportData(data);
        this.reportVersion = getReportVersion();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async getSecuredMessagesSummaryReport() {
      const selectedTimeFrame = convertTimeFrameToUnix(this.selectedTimeFrame!);
      const request = {
        ...api.getSecuredMessagesReport(selectedTimeFrame),
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);
        this.securedMessagesSummaryReport = data;
        this.reportVersion = getReportVersion();
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    async uploadPdfReport(filename: string, pdfBlob: Blob, reportType: ReportType) {
      const data = new FormData();
      data.append("file", pdfBlob, filename);
      const request = {
        ...api.uploadPdfReport,
        data,
        params: {
          reportType,
        },
      };

      try {
        await axiosInstance.request(request);
      } catch (e) {
        console.error(e);
      }
    },
  },
});

export function getReportVersion(): string {
  return moment().format("YYYYMMDD_HHmmss");
}

function normalizePhishingSimulationReportData(
  rawData: PhishingSimulationReportData
): PhishingSimulationReportData {
  const { simulationEngagement, historic } = rawData;

  const sortedSimulations = simulationEngagement.topFailedSimulations.sort(
    (a, b) => b.failurePercentage - a.failurePercentage
  );

  const sortedUsers = simulationEngagement.topPhishedUsers.sort((a, b) => b.offenses - a.offenses);

  return {
    ...rawData,
    simulationEngagement: {
      topFailedSimulations: sortedSimulations,
      topPhishedUsers: sortedUsers,
    },
    historic: historic
      .map((item) => ({
        ...item,
        date: moment().month(item.month).year(item.year).valueOf(),
      }))
      .sort((a, b) => a.date - b.date),
  };
}

function normalizeSecurityAwarenessReportData(rawData: SecurityAwarenessReportData) {
  const { trainingEngagementSummary } = rawData;

  const sortedTrainings = trainingEngagementSummary.trainingStatus.sort(
    (a, b) => b.percentage - a.percentage
  );

  const sortedUsers = trainingEngagementSummary.overdueCourses.sort(
    (a, b) => b.offenses - a.offenses
  );

  return {
    ...rawData,
    trainingEngagementSummary: {
      trainingStatus: sortedTrainings,
      overdueCourses: sortedUsers,
    },
  };
}
