<template>
  <div class="content-md margin-auto mt-4">
    <div class="d-flex align-center justify-end mb-4">
      <date-range-picker
        v-model="localFilters.reportTimeFrame"
        :preset-dates="presets"
        :class="{
          'filter-active': localFilters.reportTimeFrame.start && localFilters.reportTimeFrame.end,
        }"
        :placeholder="$t('reports.executiveSummaryReport.dateRange')"
        class="date-range-picker"
      ></date-range-picker>
    </div>
    <v-card class="pa-12" v-if="loading || showSkeletonLoader">
      <v-skeleton-loader color="transparent" type="heading,list-item-three-line" />
      <v-row>
        <v-col v-for="item in 3" :key="item" cols="4">
          <v-skeleton-loader color="transparent" type="avatar, list-item-two-line">
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-skeleton-loader
        color="transparent"
        type="heading,list-item-three-line, article, article"
      />
      <v-skeleton-loader class="mx-3 rounded-lg" color="transparent" type="image" />
    </v-card>
    <v-card class="pa-12 mt-1" v-else-if="phishingSimulationReport" id="report-card">
      <div class="d-flex justify-space-between align-start">
        <v-chip
          class="px-2 py-1 mb-3"
          label
          rounded="3"
          color="indigo-faint"
          variant="flat"
          density="compact"
        >
          <span class="coro-label text-uppercase">
            {{ account.customerName }}
          </span>
        </v-chip>
        <img alt="Logo" :src="logoPath" width="56" />
      </div>
      <div class="mb-14">
        <div class="headline5">
          {{ $t("reports.phishingSimulationReport.title") }}
        </div>
        <div class="body2 mb-2">
          {{ formattedSelectedDateRange }}
        </div>
        <div class="body3 text--semitransparent mb-2">
          {{ $t("reports.executiveSummaryReport.generatedOn") }} {{ generatedOn }}
        </div>
        <div class="body3 text--semitransparent">
          {{ $t("reports.executiveSummaryReport.version") }} {{ reportVersion }}
        </div>
      </div>

      <div class="es-report__section-title mb-6">
        {{ $t("reports.phishingSimulationReport.simulationSummary") }}
      </div>

      <div class="d-flex">
        <div class="doughnut-chart-wrapper">
          <doughnut-chart class="mb-8 html2pdf__page-break" :data="chartData" :options="options" />
        </div>
        <div class="ml-4 w-100 d-flex flex-column justify-center">
          <div class="d-flex justify-space-between">
            <div>
              <div class="inner-circle red-circle mr-2" />
              <span>{{ $t("reports.phishingSimulationReport.failed") }}</span>
            </div>
            <div>
              <span class="subtitle2"
                >{{ getPercentage(phishingSimulationReport.totalSent, summaryData.failed) }}%</span
              >
              ({{ summaryData.failed }})
            </div>
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>
          <div class="d-flex justify-space-between">
            <div>
              <div class="inner-circle green-circle mr-2" />
              <span>{{ $t("reports.phishingSimulationReport.noAction") }}</span>
            </div>
            <div>
              <span class="subtitle2"
                >{{
                  getPercentage(phishingSimulationReport.totalSent, summaryData.noAction)
                }}%</span
              >
              ({{ summaryData.noAction }})
            </div>
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>
          <div class="d-flex justify-space-between">
            <div>
              <div class="inner-circle blue-circle mr-2" />
              <span>{{ $t("reports.phishingSimulationReport.reportedPhishing") }}</span>
            </div>
            <div>
              <span class="subtitle2"
                >{{
                  getPercentage(phishingSimulationReport.totalSent, summaryData.reported)
                }}%</span
              >
              ({{ summaryData.reported }})
            </div>
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>
        </div>
      </div>

      <div class="html2pdf__page-break"></div>
      <div class="es-report__section-title mb-6 mt-8">
        {{ $t("reports.phishingSimulationReport.failureRatePerMonth") }}
      </div>
      <BarChart
        class="mb-8 html2pdf__page-break bg-white"
        :data="failurePerMonthData"
        :options="barChartOptions"
        :barColor="'#EFB3AB'"
      />
      <div class="es-report__section-title mb-6 mt-8">
        {{ $t("reports.phishingSimulationReport.simulationEngagement") }}
      </div>
      <div class="d-flex flex-row">
        <div class="v-col-6">
          <div class="d-flex justify-space-between">
            <div
              class="subtitle2 mb-4"
              v-html="$t('reports.phishingSimulationReport.topFailedSimulations')"
            ></div>
            <div>
              <div class="inner-circle purple-circle mr-2" />
              <span>{{ $t("reports.phishingSimulationReport.trainingCompletedLegend") }}</span>
            </div>
          </div>
          <div
            v-for="(training, index) in topFailedSimulationsSliced"
            :key="index"
            :class="{
              'mb-4': index !== simulationEngagementSummaryData.topFailedSimulations.length,
            }"
          >
            <span>{{ training.simulationName }}</span>
            <v-tooltip open-delay="100" location="top">
              <template #activator="{ props }">
                <div class="d-flex align-center position-relative">
                  <coro-progress
                    v-bind="props"
                    class="position-absolute"
                    :value="getPercentage(training.simulationsSent, training.trainingsCompleted)"
                    :height="4"
                    :color="COLORS.PURPLE_FULL"
                  >
                  </coro-progress>
                  <coro-progress
                    v-bind="props"
                    :value="training.failurePercentage"
                    :height="4"
                    color="rgb(var(--v-theme-indigo-medium))"
                  >
                  </coro-progress>
                  <span class="ml-3">{{ training.failurePercentage }}%</span>
                </div>
              </template>
              <div>
                <div>
                  {{
                    $t("reports.phishingSimulationReport.totalSimulationSent", {
                      n: training.simulationsSent,
                    })
                  }}
                </div>
                <div>
                  {{ $t("reports.phishingSimulationReport.totalFailed", { n: training.failed }) }}
                </div>
                <div>
                  {{
                    $t("reports.phishingSimulationReport.trainingCompleted", {
                      n: training.trainingsCompleted,
                    })
                  }}
                </div>
              </div>
            </v-tooltip>
          </div>
          <span
            class="d-inline-flex align-center link-with-icon"
            data-html2canvas-ignore
            v-if="simulationEngagementSummaryData.topFailedSimulations.length > 5"
          >
            <span
              class="coro-link view-all-button"
              @click="showAllTrainingsClicked = !showAllTrainingsClicked"
            >
              {{ showAllTrainingsClicked ? $t("general.viewLess") : $t("general.viewAll") }}
              <v-icon
                class="icon-link"
                icon="$chevron"
                :class="{ rotated: showAllTrainingsClicked }"
              ></v-icon>
            </span>
          </span>
        </div>
        <v-divider :vertical="true"></v-divider>
        <div class="v-col-6">
          <div
            class="subtitle2 mb-4"
            v-html="$t('reports.phishingSimulationReport.topPhishedUsers')"
          ></div>
          <div v-for="(user, index) in usersListSliced" class="page-break-avoid" :key="user.userId">
            <div class="d-flex justify-space-between mb-4">
              <span>{{ user.userId }}</span>
              <span>{{ user.offenses }}</span>
            </div>
            <v-divider v-if="index !== usersListSliced.length - 1" class="mb-4"></v-divider>
          </div>
          <span
            class="d-inline-flex align-center link-with-icon"
            data-html2canvas-ignore
            v-if="simulationEngagementSummaryData.topPhishedUsers.length > 5"
          >
            <span class="coro-link" @click="showAllUsersClicked = !showAllUsersClicked">
              {{ showAllUsersClicked ? $t("general.viewLess") : $t("general.viewAll") }}
              <v-icon
                class="icon-link"
                icon="$chevron"
                :class="{ rotated: showAllUsersClicked }"
              ></v-icon>
            </span>
          </span>
        </div>
      </div>

      <div class="html2pdf__page-break"></div>
      <div class="es-report__section-title mb-6 mt-8">
        {{ $t("reports.phishingSimulationReport.listOfUsers") }}
      </div>

      <div class="d-flex justify-space-between" data-html2canvas-ignore>
        <div>
          <v-text-field
            v-model="userSearch"
            variant="outlined"
            class="mt-3 report-user-search"
            density="compact"
            rounded="xl"
            bg-color="indigo-faint"
            width="280"
            prepend-inner-icon="icon-search"
            :placeholder="$t('general.search')"
          ></v-text-field>
        </div>
        <div
          @click="exportCsv()"
          class="export-btn body2 coro-link item-clickable d-flex align-center mr-4"
        >
          <v-icon icon="$export" color="link" size="16" />
          <span>{{ $t("reports.phishingSimulationReport.exportAllUsers") }}</span>
        </div>
      </div>
      <v-data-table
        disable-sort
        :items="searchedUsers"
        item-value="userId"
        v-model:expanded="expanded"
        :items-per-page="-1"
        :items-length="0"
        hide-default-footer
      >
        <template #headers>
          <tr>
            <th class="w-5"></th>
            <th class="w-20">
              {{ $t("reports.phishingSimulationReport.user") }}
            </th>
            <th class="pr-6 w-15">
              {{ $t("reports.phishingSimulationReport.simulationsSent") }}
            </th>
            <th class="pr-6">
              {{ $t("reports.phishingSimulationReport.failed") }}
            </th>
            <th class="pr-6">
              {{ $t("reports.phishingSimulationReport.noAction") }}
            </th>
            <th class="pr-6">
              {{ $t("reports.phishingSimulationReport.reported") }}
            </th>
            <th class="pr-6 w-10 phishing-completed-column">
              <v-tooltip open-delay="300" location="top">
                <template #activator="{ props }">
                  <div class="phrase-wrapper" v-bind="props">
                    {{ $t("reports.phishingSimulationReport.phishingTrainingCompleted") }}
                  </div>
                </template>
                <div>{{ $t("reports.phishingSimulationReport.phishingTrainingCompleted") }}</div>
              </v-tooltip>
            </th>
            <th class="pr-6">
              {{ $t("reports.phishingSimulationReport.failureRate") }}
            </th>
          </tr>
        </template>
        <template #no-data></template>
        <template v-slot:body.append v-if="searchedUsers.length === 0">
          <tr>
            <td :colspan="8" class="text-center">
              {{ $t("general.noData") }}
            </td>
          </tr>
        </template>
        <template #item="{ item, toggleExpand, isExpanded, internalItem }">
          <tr>
            <td class="w-5">
              <v-icon
                class="mr-2 cursor-pointer"
                icon="$dropdown"
                color="primary"
                :class="{ rotated: isExpanded(internalItem) }"
                @click="toggleExpand(internalItem)"
              />
            </td>
            <td class="subtitle2">{{ item.userId }}</td>
            <td>{{ item.simulationsSent }}</td>
            <td>{{ item.clicked }}</td>
            <td>{{ item.noAction }}</td>
            <td>{{ item.reported }}</td>
            <td>{{ `${item.phishingTrainingsCompleted}/${item.totalTrainings}` }}</td>
            <td
              :class="{
                'text-red-dark': item.failedPercentage > 70,
                'text-green-dark': item.failedPercentage <= 70,
              }"
            >
              {{ item.failedPercentage }}%
            </td>
          </tr>
        </template>
        <template v-slot:expanded-row="{ item }">
          <tr v-for="simulation in item.phishingSimulations" :key="simulation.emailName">
            <td></td>
            <td colspan="2">
              {{ getFormattedDateTime(simulation.receivedDate, "ll") }} - {{ simulation.emailName }}
            </td>
            <td>
              <div v-if="simulation.failed" class="inner-circle dot mr-2" />
            </td>
            <td>
              <div v-if="simulation.noAction" class="inner-circle dot mr-2" />
            </td>
            <td>
              <div v-if="simulation.reported" class="inner-circle dot mr-2" />
            </td>
            <td>
              <div v-if="simulation.associatedTrainingCompleted" class="inner-circle dot mr-2" />
            </td>
            <td></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useAccountStore } from "@/_store/account.module";
import moment from "moment";
import { ReportType, useReportsStore } from "@/_store/reports.module";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { useSWGStore } from "@/_store/secure-web-gateway.module";
import { getDateRangePresets } from "@/constants/date-range-picker";
import { useReportsTab } from "@/composables/useReportsTab";
import DoughnutChart from "@/components/DoughnutChart.vue";
import { DoughnutChartColors } from "@/constants/charts.ts";
import { getFormattedDateTime, getPercentage } from "@/_helpers/utils.ts";
import { COLORS } from "@/constants/colors.ts";
import { useExportsStore } from "@/_store/exports.module.ts";
import BarChart from "@/components/BarChart.vue";
import CoroProgress from "@/components/CoroProgress.vue";
import type { TimeFrame } from "@/types.ts";

export default defineComponent({
  components: {
    BarChart,
    DateRangePicker,
    DoughnutChart,
    CoroProgress,
  },
  setup() {
    const i18n = useI18n();
    const userSearch = ref("");
    const accountStore = useAccountStore();
    const reportsStore = useReportsStore();
    const swgStore = useSWGStore();
    const exportsStore = useExportsStore();
    const reportType = ref(null);
    const { account } = storeToRefs(accountStore);
    const { showSkeletonLoader, settings } = storeToRefs(swgStore);
    const {
      dnsSummaryReport,
      loading,
      reportVersion,
      phishingSimulationReport,
      selectedTimeFrame,
    } = storeToRefs(reportsStore);
    const { localFilters, logoPath, formattedSelectedDateRange } = useReportsTab(
      ReportType.PHISHING_SIMULATION,
      366
    );
    const expanded = ref<string[]>([]);

    const showAllTrainingsClicked = ref(false);
    const showAllUsersClicked = ref(false);

    const generatedOn = computed(() =>
      moment(reportVersion.value, "YYYYMMDD_HHmmss").format("L LT")
    );

    const presets = getDateRangePresets();

    onMounted(async () => {
      await reportsStore.getPhishingSimulationReport();
    });

    const summaryData = computed(() => phishingSimulationReport.value.phishingSimulationReport);
    const simulationEngagementSummaryData = computed(
      () => phishingSimulationReport.value.simulationEngagement
    );

    const exportCsv = async () => {
      exportsStore.exportPhishingSimulationReportToCsv(
        selectedTimeFrame?.value || ({} as TimeFrame)
      );
    };

    const topFailedSimulationsSliced = computed(() => {
      if (showAllTrainingsClicked.value) {
        return simulationEngagementSummaryData.value.topFailedSimulations;
      } else {
        return simulationEngagementSummaryData.value.topFailedSimulations.slice(0, 5);
      }
    });

    const usersListSliced = computed(() => {
      if (showAllUsersClicked.value) {
        return simulationEngagementSummaryData.value.topPhishedUsers;
      } else {
        return simulationEngagementSummaryData.value.topPhishedUsers.slice(0, 5);
      }
    });

    const searchedUsers = computed(() => {
      return (
        phishingSimulationReport.value.userDetails.filter(({ userId }) =>
          userId.includes(userSearch.value)
        ) || []
      );
    });

    const chartData = computed(() => {
      const reportedPercentage = getPercentage(
        phishingSimulationReport.value.totalSent,
        summaryData.value.reported
      );
      const failedPercentage = getPercentage(
        phishingSimulationReport.value.totalSent,
        summaryData.value.failed
      );
      const noActionPercentage = getPercentage(
        phishingSimulationReport.value.totalSent,
        summaryData.value.noAction
      );

      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [
          i18n.t("reports.phishingSimulationReport.failed"),
          i18n.t("reports.phishingSimulationReport.noAction"),
          i18n.t("reports.phishingSimulationReport.reportedPhishing"),
        ],
        datasets: [
          {
            label: "",
            backgroundColor: [
              DoughnutChartColors.ERROR,
              DoughnutChartColors.SUCCESS,
              DoughnutChartColors.BLUE,
            ],
            hoverBackgroundColor: [
              DoughnutChartColors.ERROR,
              DoughnutChartColors.SUCCESS,
              DoughnutChartColors.BLUE,
            ],
            data: [failedPercentage, noActionPercentage, reportedPercentage],
          },
        ],
      };
    });

    const failurePerMonthData = computed(() => {
      return phishingSimulationReport.value.historic.map(
        ({ date, failurePercentage, ...additionalData }) => ({
          x: moment(date).format("MMM"),
          y: failurePercentage,
          additionalData: {
            ...additionalData,
            month: moment(date).format("MMM YYYY"),
          },
        })
      );
    });

    const options = ref({
      cutout: "80%",
      insideTextData: {
        title: phishingSimulationReport.value.totalSent,
        subtitle: i18n.t("reports.phishingSimulationReport.simulationsEmailsSent"),
      },
      hoverOffset: 0,
    });

    const barChartOptions = computed(() => {
      return {
        scales: {
          y: {
            beginAtZero: true,
            max: 100, // Ensure y-axis goes up to 100%
            ticks: {
              callback: (value: string) => `${value}%`, // Append "%" to Y-axis labels
            },
          },
        },
        plugins: {
          tooltip: {
            displayColors: false,
            padding: 8,
            backgroundColor: COLORS.PRIMARY,
            titleFont: {
              size: 14,
              weight: 400,
              family: "'Source Sans Pro', sans-serif",
            },
            bodyFont: {
              size: 14,
              weight: 400,
              family: "'Source Sans Pro', sans-serif",
            },
            callbacks: {
              title: (tooltipItems: Array<any>) => {
                const tooltip: any = tooltipItems[0];
                const data = tooltip.raw.additionalData;
                const failureRate = tooltip.raw.y;
                return (
                  data.month +
                  "\n" +
                  i18n.t("reports.phishingSimulationReport.simulationsClicked", {
                    n: data.offenses,
                    p: failureRate,
                  }) +
                  "\n" +
                  i18n.t("reports.phishingSimulationReport.trainingsCompleted", {
                    n: data.trainingsCompleted,
                    p: data.trainingsCompletedPercentage,
                  })
                );
              },
              label: () => "",
            },
          },
        },
      };
    });

    watch(
      () => phishingSimulationReport.value.totalSent,
      (val) => {
        options.value.insideTextData.title = val;
      },
      { immediate: false }
    );

    return {
      chartData,
      userSearch,
      options,
      barChartOptions,
      generatedOn,
      expanded,
      exportCsv,
      searchedUsers,
      COLORS,
      account,
      topFailedSimulationsSliced,
      usersListSliced,
      showAllTrainingsClicked,
      showAllUsersClicked,
      simulationEngagementSummaryData,
      failurePerMonthData,
      getPercentage,
      summaryData,
      dnsSummaryReport,
      phishingSimulationReport,
      loading,
      logoPath,
      localFilters,
      formattedSelectedDateRange,
      presets,
      reportVersion,
      reportType,
      getFormattedDateTime,
      showSkeletonLoader,
      settings,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-field__outline {
    opacity: 0;
  }

  .v-data-table-rows-no-data {
    display: none;
  }
}

.range-picker {
  max-width: 280px;
}

.doughnut-chart-wrapper {
  height: 260px;
  width: 260px;
}

.v-chip {
  border-radius: 6px;
}

.provider-selection-chip {
  max-height: 71px;
}

.v-chip .v-chip__content {
  width: 100% !important;
  text-align: center;
}

.date-range-picker {
  width: 280px !important;
}

.es-report {
  .range-picker {
    max-width: 280px;
  }
  &__section-title {
    border-radius: 4px;
    background: rgb(var(--v-theme-primary));
    color: rgb(var(--v-theme-white));
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    line-height: 130%;
    font-weight: 600;
    padding: 8px;
    margin-bottom: 8px;
  }
}

.percentage-bar {
  height: 4px;
  border-radius: 2px;
  background: rgb(var(--v-theme-indigo-light));
}

.icon-link::before {
  color: rgb(var(--v-theme-link));
}

.link-with-icon {
  border-bottom: 2px solid rgb(var(--v-theme-white));

  &:hover {
    border-bottom: 2px solid rgb(var(--v-theme-link));
  }

  .coro-link {
    text-decoration: none;
  }
}

.export-btn {
  text-decoration: none !important;
  .icon {
    text-decoration: none;
  }

  &:hover span {
    text-decoration: underline; /* Underline only the text */
  }
}

.purple-circle {
  background-color: #cd8de5;
  border-color: #cd8de5;
}

.dot {
  background-color: rgb(var(--v-theme-primary));
  min-height: 6px;
  min-width: 6px;
  height: 6px;
  width: 6px;
}

.phishing-completed-column {
  white-space: nowrap;
}

.phrase-wrapper {
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
