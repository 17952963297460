<template>
  <v-skeleton-loader :type="coronetSkeletonLoaderTypes.CARD" :loading="loading">
    <div class="d-flex justify-space-between">
      <v-btn-toggle
        v-model="ztnaActive"
        class="d-flex mb-6"
        density="compact"
        selected-class="bg-white"
        variant="plain"
        mandatory
        group
        @update:model-value="handleToggle"
      >
        <v-btn
          class="flex-grow-1"
          :class="{ 'toggle-disabled': ztnaEnabled }"
          :disabled="ztnaEnabled"
          :value="false"
          :text="$t('network.virtualOffice.modes.vpn')"
        />
        <v-btn class="flex-grow-1" :value="true" :text="$t('network.virtualOffice.modes.ztna')" />
      </v-btn-toggle>

      <v-btn
        :text="$t('network.virtualOffice.vpnMobileApp')"
        border
        rounded
        @click="onVpnMobileAppClick"
      >
        <template #prepend>
          <v-icon icon="$trailingLink" />
        </template>
      </v-btn>
    </div>
    <component
      v-if="showComponent"
      :settings="virtualOfficeSettings"
      :is="getComponent"
      @show-all-ips="onShowAllIpsClick"
    />
  </v-skeleton-loader>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import VirtualOfficeVpnConfiguration from "@/components/network/VirtualOfficeVpnConfiguration.vue";
import VirtualOfficeZtnaConfiguration from "@/components/network/VirtualOfficeZtnaConfiguration.vue";
import { useRouter } from "vue-router";
import { useZtnaStore } from "@/_store/network/ztna.module";
import { storeToRefs } from "pinia";
import { useDialogsStore } from "@/_store/dialogs.module";
import { componentDialogsConfigConstructor, listDialogConstructor } from "@/_helpers/utils";
import VpnMobileAppModal from "@/components/modals/network/VpnMobileAppModal.vue";
import { ModalWidth } from "@/constants/modals";
import { VirtualOfficeAction } from "@/constants/network";
import { useVirtualOfficeStore } from "@/_store/network/virtual-office.module";
import { useI18n } from "vue-i18n";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader.ts";

export default defineComponent({
  components: {
    VirtualOfficeZtnaConfiguration,
    VirtualOfficeVpnConfiguration,
  },
  setup() {
    const router = useRouter();
    const ztnaActive = ref<boolean>(false);
    const { t } = useI18n();
    const loading = ref(false);
    const { openDialog, closeDialog } = useDialogsStore();
    const virtualOfficeStore = useVirtualOfficeStore();
    const ztnaStore = useZtnaStore();
    const { initZtna } = ztnaStore;
    const { getVpnSettings } = virtualOfficeStore;
    const { settings: virtualOfficeSettings } = storeToRefs(virtualOfficeStore);
    const { enabled: ztnaEnabled } = storeToRefs(ztnaStore);
    const handleToggle = (val: boolean) => {
      router.push({
        query: { ...router.currentRoute.value.query, ztna: String(val) },
      });
    };

    const getComponent = computed(() =>
      ztnaActive.value ? VirtualOfficeZtnaConfiguration : VirtualOfficeVpnConfiguration
    );

    const onVpnMobileAppClick = () => {
      openDialog({
        ...componentDialogsConfigConstructor({
          component: VpnMobileAppModal,
          action: VirtualOfficeAction.GET_VPN_MOBILE_APP,
          width: ModalWidth.LARGE,
          callback: () => ({}),
        }),
        footer: null,
      });
    };

    const showComponent = computed(() => Object.keys(virtualOfficeSettings.value).length > 0);

    const onShowAllIpsClick = () => {
      openDialog({
        ...listDialogConstructor({
          action: VirtualOfficeAction.VIEW_ALL_IPS,
          item: {
            items: virtualOfficeSettings.value.servers,
          },
        }),
        footer: {
          buttons: [
            {
              title: t(`modals.${VirtualOfficeAction.VIEW_ALL_IPS}.actionBtn`),
              color: "primary",
              spacer: "before",
              callback: () => {
                closeDialog();
              },
            },
          ],
        },
      });
    };
    onMounted(async () => {
      loading.value = true;
      await initZtna();
      await getVpnSettings();
      loading.value = false;
      const {
        query: { ztna: ztnaQuery },
      } = router.currentRoute.value;
      ztnaActive.value = ztnaEnabled.value || ztnaQuery === "true";
    });

    return {
      onShowAllIpsClick,
      showComponent,
      onVpnMobileAppClick,
      ztnaActive,
      handleToggle,
      getComponent,
      ztnaEnabled,
      virtualOfficeSettings,
      coronetSkeletonLoaderTypes,
      loading,
    };
  },
});
</script>

<style scoped lang="scss">
.v-btn-toggle {
  background-color: rgb(var(--v-theme-indigo-pale));
  width: 300px;
  padding: 3px;
}
</style>
