<template>
  <div class="coro-widget">
    <div v-if="widgetData">
      <template v-if="isCloudAppsConnected && isSecurityAwarenessAllowed">
        <div class="coro-widget__content">
          <div class="coro-widget__tile">
            <div class="ml-5 mb-4 mt-3">
              <div class="d-inline-flex">
                <div class="d-flex align-start flex-column">
                  <div class="coro-widget-number" data-testid="users-widget-protected-users-count">
                    {{ usersReceivedPhishingTrainingEmails }}
                  </div>
                  <div class="body2">
                    <span> {{ $t("dashboard.widgets.securityAwareness.emailsSent") }} </span>
                    <router-link
                      v-if="!isAccessRestricted(rolePermissionsScope.VIEWS, 'usersView')"
                      :to="{ name: RouteName.REPORTS_PHISHING_SIMULATION_SUMMARY_TAB }"
                    >
                      <a class="coro-link text-no-wrap ml-2" data-testid="users-widget-view-users"
                        >{{ $t("dashboard.widgets.securityAwareness.viewReport") }} ›</a
                      >
                    </router-link>
                  </div>
                  <div class="body2 text-indigo-medium">
                    {{ $t("dashboard.widgets.securityAwareness.last90Days") }}
                  </div>
                </div>
              </div>
              <div
                class="coro-widget__tile__doughnut-chart"
                v-if="usersReceivedPhishingTrainingEmails"
              >
                <doughnut-chart :data="chartData.phishingEmailsChart"> </doughnut-chart>
              </div>
            </div>
          </div>
          <div class="coro-widget__tile body1">
            <div class="ml-5 mb-4 mt-3 mr-5">
              <div
                class="mb-4"
                v-html="$t('dashboard.widgets.securityAwareness.topPhishedUsers')"
              ></div>
              <div v-if="!usersFailedPhishing.length" class="body2 text-indigo-medium">
                {{ $t("dashboard.widgets.securityAwareness.noData") }}
              </div>
              <div class="body2 mb-4" v-for="{ email, count } in usersFailedPhishing" :key="email">
                <div>{{ email }}</div>
                <div class="d-flex align-center">
                  <coro-progress
                    :value="getPercentage(totalUsersFailedPhishing, count)"
                    :height="4"
                    color="rgb(var(--v-theme-indigo-medium))"
                    class="mr-2"
                  >
                  </coro-progress>
                  {{ count }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="coro-widget__content border-t">
          <div class="coro-widget__tile">
            <div class="ml-5 mb-4 mt-3">
              <div class="d-inline-flex">
                <div class="d-flex align-start flex-column">
                  <div class="d-flex align-baseline">
                    <div
                      class="coro-widget-number"
                      data-testid="users-widget-protected-users-count"
                    >
                      {{ usersSecurityTrainingStatistics }}
                    </div>
                    <span v-if="widgetData.adaptiveCourses" class="ml-2 text-indigo-medium">
                      ({{
                        $t("dashboard.widgets.securityAwareness.adaptiveTrainings", {
                          n: widgetData.adaptiveCourses,
                        })
                      }})
                    </span>
                  </div>
                  <div class="body2">
                    <span> {{ $t("dashboard.widgets.securityAwareness.trainingsSent") }} </span>
                    <router-link
                      v-if="!isAccessRestricted(rolePermissionsScope.VIEWS, 'usersView')"
                      :to="{ name: RouteName.REPORTS_SECURITY_AWARENESS_TAB }"
                    >
                      <a class="coro-link text-no-wrap ml-2" data-testid="users-widget-view-users">
                        {{ $t("dashboard.widgets.securityAwareness.viewReport") }} ›
                      </a>
                    </router-link>
                  </div>
                  <div class="body2 text-indigo-medium">
                    {{ $t("dashboard.widgets.securityAwareness.last90Days") }}
                  </div>
                </div>
              </div>
              <div class="coro-widget__tile__doughnut-chart" v-if="usersSecurityTrainingStatistics">
                <doughnut-chart :data="chartData.securityTrainingChart"> </doughnut-chart>
              </div>
            </div>
          </div>
          <div class="coro-widget__tile body1">
            <div class="ml-5 mb-4 mt-3 mr-5">
              <div
                class="mb-4"
                v-html="$t('dashboard.widgets.securityAwareness.topUsersWithOverdueTraining')"
              ></div>
              <div v-if="!usersFailedTraining.length" class="body2 text-indigo-medium">
                {{ $t("dashboard.widgets.securityAwareness.noData") }}
              </div>
              <div class="body2 mb-4" v-for="{ email, count } in usersFailedTraining" :key="email">
                <div>{{ email }}</div>
                <div class="d-flex align-center">
                  <coro-progress
                    :value="getPercentage(totalUsersFailedTraining, count)"
                    :height="4"
                    color="rgb(var(--v-theme-indigo-medium))"
                    class="mr-2"
                  >
                  </coro-progress>
                  {{ count }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="coro-widget__content coro-widget--empty">
        <security-awareness-empty-state :services="widgetData.services" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import { useWidgetContent } from "@/composables/useWidgetContent";
import { kebabCase } from "lodash";
import { DoughnutChartColors } from "@/constants/charts";
import { useI18n } from "vue-i18n";
import { TicketsModuleFilter } from "@/constants/tickets";
import { CoroIcons } from "@/constants/coro-icon";
import { ServiceStatus } from "@/constants/cloud-apps";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import DoughnutChart from "@/components/DoughnutChart.vue";
import type {
  SecurityAwarenessWidgetData,
  SecurityAwarenessWidgetUserRecord,
  WidgetConfig,
} from "@/_store/dashboard/dashboard.module";
import { RouteName } from "@/constants/routes";
import { getPercentage } from "@/_helpers/utils.ts";
import CoroProgress from "@/components/CoroProgress.vue";
import SecurityAwarenessEmptyState from "@/components/SecurityAwarenessEmptyState.vue";
import { useSecurityAwarenessEmptyState } from "@/composables/useSecurityAwarenessEmptyState.ts";

export default defineComponent({
  props: {
    widgetData: {
      type: Object as PropType<SecurityAwarenessWidgetData>,
      required: true,
    },
    widgetConfig: {
      type: Object as PropType<WidgetConfig>,
      required: true,
    },
  },
  components: {
    SecurityAwarenessEmptyState,
    CoroProgress,
    DoughnutChart,
  },
  setup(props) {
    const i18n = useI18n();
    const { viewVulnerability, viewAllTickets, isAccessRestricted } = useWidgetContent();
    const { isCloudAppsConnected, isSecurityAwarenessAllowed } = useSecurityAwarenessEmptyState();

    const sortUsers = (entry: SecurityAwarenessWidgetUserRecord[]) => {
      return entry.filter((item) => item.count).sort((itemA, itemB) => itemB.count - itemA.count);
    };

    const usersFailedPhishing = computed(() => {
      const { topUsersFailedPhishing } = props.widgetData || {};

      return sortUsers(topUsersFailedPhishing);
    });

    const usersFailedTraining = computed(() => {
      const { topUsersFailedTraining } = props.widgetData || {};

      return sortUsers(topUsersFailedTraining);
    });

    const calculateTotal = (items: SecurityAwarenessWidgetUserRecord[]) => {
      return items.reduce((sum: number, curr: SecurityAwarenessWidgetUserRecord) => {
        return sum + curr.count;
      }, 0);
    };

    const totalUsersFailedPhishing = computed(() => {
      const { topUsersFailedPhishing } = props.widgetData || {};

      return calculateTotal(topUsersFailedPhishing);
    });

    const totalUsersFailedTraining = computed(() => {
      const { topUsersFailedTraining } = props.widgetData || {};

      return calculateTotal(topUsersFailedTraining);
    });

    const usersReceivedPhishingTrainingEmails = computed(() => {
      const { simulationsFailed, simulationsReported, simulationsIgnored } = props.widgetData || {};

      return simulationsFailed + simulationsReported + simulationsIgnored;
    });

    const usersSecurityTrainingStatistics = computed(() => {
      const { coursesCompleted, coursesOverdue, coursesPending } = props.widgetData || {};

      return coursesCompleted + coursesOverdue + coursesPending;
    });

    const getPercentageForChart = (sum: number, target: number): string => {
      return `${target} (${getPercentage(sum, target)}%)`;
    };

    const chartData = computed(() => {
      const {
        simulationsFailed,
        simulationsReported,
        simulationsIgnored,
        coursesCompleted,
        coursesOverdue,
        coursesPending,
      } = props.widgetData || {};

      return {
        RouteName,
        phishingEmailsChart: {
          hoverBackgroundColor: "red",
          hoverBorderWidth: 10,
          labels: [
            i18n.t("dashboard.widgets.securityAwareness.labels.reportedPhishing", {
              n: getPercentageForChart(
                usersReceivedPhishingTrainingEmails.value,
                simulationsReported
              ),
            }),
            i18n.t("dashboard.widgets.securityAwareness.labels.clickedSimulations", {
              n: getPercentageForChart(
                usersReceivedPhishingTrainingEmails.value,
                simulationsFailed
              ),
            }),
            i18n.t("dashboard.widgets.securityAwareness.labels.noAction", {
              n: getPercentageForChart(
                usersReceivedPhishingTrainingEmails.value,
                simulationsIgnored
              ),
            }),
          ],
          datasets: [
            {
              label: "",
              backgroundColor: [
                DoughnutChartColors.BLUE,
                DoughnutChartColors.ERROR,
                DoughnutChartColors.SUCCESS,
              ],
              hoverBackgroundColor: [
                DoughnutChartColors.BLUE,
                DoughnutChartColors.ERROR,
                DoughnutChartColors.SUCCESS,
              ],
              data: [simulationsIgnored, simulationsFailed, simulationsReported],
            },
          ],
        },
        securityTrainingChart: {
          hoverBackgroundColor: "red",
          hoverBorderWidth: 10,
          labels: [
            i18n.t("dashboard.widgets.securityAwareness.labels.completed", {
              n: getPercentageForChart(usersSecurityTrainingStatistics.value, coursesCompleted),
            }),
            i18n.t("dashboard.widgets.securityAwareness.labels.overdue", {
              n: getPercentageForChart(usersSecurityTrainingStatistics.value, coursesOverdue),
            }),
            i18n.t("dashboard.widgets.securityAwareness.labels.toDo", {
              n: getPercentageForChart(usersSecurityTrainingStatistics.value, coursesPending),
            }),
          ],
          datasets: [
            {
              label: "",
              backgroundColor: [
                DoughnutChartColors.SUCCESS,
                DoughnutChartColors.ERROR,
                DoughnutChartColors.GRAY,
              ],
              hoverBackgroundColor: [
                DoughnutChartColors.SUCCESS,
                DoughnutChartColors.ERROR,
                DoughnutChartColors.GRAY,
              ],
              data: [coursesCompleted, coursesOverdue, coursesPending],
            },
          ],
        },
      };
    });

    return {
      totalUsersFailedPhishing,
      totalUsersFailedTraining,
      getPercentage,
      coronetIcons: CoroIcons,
      serviceStatuses: ServiceStatus,
      ticketsWidgetFilters: TicketsModuleFilter,
      rolePermissionsScope: RolePermissionsScope,
      workspaceManagementScope: WorkspaceManagementScopeSections,
      usersFailedPhishing,
      usersFailedTraining,
      chartData,
      usersReceivedPhishingTrainingEmails,
      usersSecurityTrainingStatistics,
      isAccessRestricted,
      kebabCase,
      viewAllTickets,
      viewVulnerability,
      RouteName,
      isCloudAppsConnected,
      isSecurityAwarenessAllowed,
    };
  },
});
</script>

<style lang="scss" scoped>
.coro-widget .coro-widget__content {
  min-height: unset !important;
}
</style>
