<template>
  <div class="content-md margin-auto">
    <v-skeleton-loader
      class="skeleton-loader--security-settings"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS_DENSE"
    >
      <template v-if="localValue">
        <v-progress-linear
          :style="{ visibility: loading ? 'visible' : 'hidden' }"
          indeterminate
          height="2px"
        />
        <v-card class="mb-6">
          <v-card-text>
            <div class="d-flex align-center">
              <span class="subtitle1 mr-6">
                {{ $t("securityAwarenessSettings.securityTraining.activate") }}
              </span>
              <v-switch
                v-model="localValue.trainingEnabled"
                class="allow-blocklist-tab-content fit-content"
                color="primary lighten-1"
                density="compact"
                hide-details
                :ripple="false"
                :disabled="actionNotAllowed"
              >
              </v-switch>
            </div>
            <div class="text-indigo-medium">
              {{ $t("securityAwarenessSettings.securityTraining.activateHint") }}
            </div>

            <v-divider class="mt-8 mb-6"></v-divider>

            <v-checkbox
              v-model="localValue.enableInductionEnrolment"
              :disabled="actionNotAllowed || !localValue.trainingEnabled"
              data-testid="enable-onboarding-checkbox"
              :ripple="false"
              :label="$t('securityAwarenessSettings.securityTraining.enableOnboarding')"
              class="mt-3 mb-1"
            />
            <div class="text-indigo-medium">
              {{ $t("securityAwarenessSettings.securityTraining.enableOnboardingHint") }}
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mb-6">
          <v-card-text>
            <div class="subtitle1">
              {{ $t("securityAwarenessSettings.securityTraining.complianceTraining") }}
            </div>
            <div class="text-indigo-medium">
              {{ $t("securityAwarenessSettings.securityTraining.complianceTrainingHint") }}
            </div>
            <v-checkbox
              v-for="training in availableTrainings"
              :key="training.trainingId"
              v-model="localValue.trainingCourses"
              :disabled="actionNotAllowed || !localValue.trainingEnabled"
              data-testid="cloud-apps-monitor-pii-checkbox"
              :value="training.trainingId"
              :ripple="false"
              multiple
              :label="training.name"
            />
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <div class="d-flex">
              <v-icon
                icon="$adaptiveTraining"
                :size="80"
                class="mr-4"
                :class="{ 'opacity-50': adaptiveTrainingDisabled }"
              ></v-icon>
              <div>
                <div class="d-flex align-center">
                  <span class="subtitle1">
                    {{ $t("securityAwarenessSettings.securityTraining.adaptiveTraining") }}
                  </span>
                  <coro-label
                    class="ml-2"
                    background-color="rgb(var(--v-theme-indigo-faint))"
                    color="rgb(var(--v-theme-primary))"
                    mix-blend-mode="normal"
                    density="xs"
                  >
                    {{ $t("betaChip.title") }}
                  </coro-label>
                  <v-switch
                    class="allow-blocklist-tab-content fit-content ml-6"
                    color="primary lighten-1"
                    density="compact"
                    hide-details
                    :ripple="false"
                    :disabled="adaptiveTrainingDisabled"
                  >
                  </v-switch>
                </div>
                <div class="text-indigo-medium">
                  {{ $t("securityAwarenessSettings.securityTraining.adaptiveTrainingHint") }}
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import type { Ref } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { storeToRefs } from "pinia";
import cloneDeep from "lodash/cloneDeep";
import { SubscriptionModule } from "@/constants/workplaces";
import { isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { debounce } from "lodash";
import {
  type SecurityTrainingSettings,
  useSecurityAwarenessModule,
} from "@/_store/security-awareness/security-awareness.module.ts";
import isEqual from "lodash/isEqual";
import CoroLabel from "@/components/CoroLabel.vue";
import { ComplianceTraining } from "@/constants/security-awareness.ts";

export default defineComponent({
  components: { CoroLabel },
  setup() {
    const securityAwarenessStore = useSecurityAwarenessModule();
    const { updateTrainingSettings, getSecurityTrainingSettings } = securityAwarenessStore;
    const { securityTrainingSettings, showSkeletonLoader, loading } =
      storeToRefs(securityAwarenessStore);
    const localValue: Ref<SecurityTrainingSettings | null> = ref(null);

    const availableTrainings = Object.values(ComplianceTraining).map((training) => ({
      trainingId: training,
      name: training.split("_").join(" "),
    }));

    onMounted(async () => {
      await getSecurityTrainingSettings();
      localValue.value = cloneDeep(securityTrainingSettings.value);
    });

    const actionNotAllowed = isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.SECURITY_AWARENESS
    );

    watch(
      () => localValue.value,
      debounce(async (newVal) => {
        if (!isEqual(newVal, securityTrainingSettings)) {
          await updateTrainingSettings(newVal);
        }
      }, 500),
      { immediate: false, deep: true }
    );

    const adaptiveTrainingDisabled = ref(true);

    return {
      coronetSkeletonLoaderTypes,
      showSkeletonLoader,
      localValue,
      adaptiveTrainingDisabled,
      actionNotAllowed,
      securityTrainingSettings,
      availableTrainings,
      loading,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-field__input {
    opacity: 1;
  }

  .v-field .v-chip {
    --v-chip-height: 32px;
  }
}
.chip-item {
  height: 36px;
  border-radius: 18px;
}

.category-icon {
  font-size: 40px;
}

.chip-content__text {
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
