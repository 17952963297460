<template>
  <div class="coro-widget">
    <div v-if="showWidgetData" class="coro-widget__content">
      <div class="coro-widget__tile">
        <template v-if="widgetData.cloudServiceStatuses.length">
          <div class="mt-3 ml-5 mb-5 mt-5">
            <div
              v-for="cloudApp of widgetData.cloudServiceStatuses"
              :key="cloudApp.name"
              class="d-flex mb-4"
            >
              <div>
                <coro-icon class="cloud-app-icon" :icon-name="cloudApp.name" />
              </div>
              <div class="ml-3">
                <div class="subtitle2">{{ $t(`services.${cloudApp.name}`) }}</div>
                <div :class="`body2 ${cloudApp.status}`">
                  <template v-if="cloudApp.status === serviceStatuses.CONNECTED">
                    {{ $t(`services.status.${cloudApp.status}`) }}
                  </template>
                  <router-link
                    v-else
                    :to="'/portal/settings/cloud-apps'"
                    class="coro-link"
                    :class="`body2 ${cloudApp.status}`"
                  >
                    {{ $t(`services.status.${cloudApp.status}`) }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
        </template>
        <div class="ml-5 mb-4 mt-3">
          <div class="d-inline-flex">
            <div class="d-flex align-start flex-column">
              <div class="coro-widget-number" data-testid="users-widget-protected-users-count">
                {{ widgetData.protectedUsers }}
              </div>
              <div class="body2">
                <span>{{ $t("dashboard.widgets.cloudSecurity.protectedUsers") }}</span>
                <router-link
                  v-if="!isAccessRestricted(rolePermissionsScope.VIEWS, 'usersView')"
                  :to="{ name: 'protected-users' }"
                >
                  <a class="coro-link text-no-wrap ml-2" data-testid="users-widget-view-users"
                    >{{ $t("general.view") }} ›</a
                  >
                </router-link>
              </div>
            </div>
          </div>
          <div class="coro-widget__tile__doughnut-chart">
            <doughnut-chart :data="chartData" @on-chart-click="onChartClick($event)">
            </doughnut-chart>
          </div>
        </div>
      </div>
      <div class="coro-widget__tile">
        <vulnerabilities-tile
          :widget-data="widgetData"
          :display-show-more-button="true"
          @all-resolved-link-clicked="
            viewAllTickets(false, ticketsWidgetFilters.CLOUD_SECURITY_WIDGET)
          "
          @all-active-link-clicked="
            viewAllTickets(true, ticketsWidgetFilters.CLOUD_SECURITY_WIDGET)
          "
          @vulnerability-clicked="
            viewVulnerability($event, ticketsWidgetFilters.CLOUD_SECURITY_WIDGET)
          "
        >
        </vulnerabilities-tile>
      </div>
    </div>
    <div v-else data-testid="cloud-apps-empty-data" class="coro-widget__content coro-widget--empty">
      <v-icon class="empty-data-icon" icon="$cloudApps" />
      <div class="headline5 text-center mt-4">
        {{ $t("dashboard.widgets.cloudSecurity.noAppsProtected") }}
      </div>
      <div class="caption text-indigo-medium mt-3 mb-8">
        {{ $t("dashboard.widgets.cloudSecurity.noAppsProtectedDescription") }}
      </div>
      <router-link
        v-if="
          !isAccessRestricted(
            rolePermissionsScope.WORKSPACE_MANAGEMENT,
            workspaceManagementScope.CLOUD_APPS
          )
        "
        :to="{ name: RouteName.CLOUD_APPS_PAGE }"
      >
        <v-btn color="primary" rounded size="large">
          {{ $t("general.connect") }}
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import { useWidgetContent } from "@/composables/useWidgetContent";
import { kebabCase } from "lodash";
import CoroIcon from "@/components/CoroIcon.vue";
import { DoughnutChartColors } from "@/constants/charts";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { TicketsModuleFilter } from "@/constants/tickets";
import { CoroIcons } from "@/constants/coro-icon";
import { ServiceStatus } from "@/constants/cloud-apps";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import VulnerabilitiesTile from "@/components/VulnerabilitiesTile.vue";
import DoughnutChart from "@/components/DoughnutChart.vue";
import type { WidgetConfig, WidgetData } from "@/_store/dashboard/dashboard.module";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  props: {
    widgetData: {
      type: Object as PropType<WidgetData>,
      required: true,
    },
    widgetConfig: {
      type: Object as PropType<WidgetConfig>,
      required: true,
    },
  },
  components: {
    CoroIcon,
    VulnerabilitiesTile,
    DoughnutChart,
  },
  setup(props) {
    const i18n = useI18n();
    const router = useRouter();
    const { viewVulnerability, viewAllTickets, isAccessRestricted } = useWidgetContent();

    const showWidgetData = computed(() => {
      return props.widgetData && props.widgetData.hasConnectedServices;
    });

    const chartData = computed(() => {
      const { usersWithoutIssues, usersWithIssues } = props.widgetData || {};

      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [
          i18n.t("dashboard.widgets.cloudSecurity.chartLabel", usersWithoutIssues),
          i18n.t("dashboard.widgets.cloudSecurity.chartLabel", usersWithIssues),
        ],
        datasets: [
          {
            label: "",
            backgroundColor: [DoughnutChartColors.SUCCESS, DoughnutChartColors.ERROR],
            hoverBackgroundColor: [DoughnutChartColors.SUCCESS, DoughnutChartColors.ERROR],
            data: [usersWithoutIssues, usersWithIssues],
          },
        ],
      };
    });

    const onChartClick = (index: number) => {
      if (isAccessRestricted(RolePermissionsScope.VIEWS, "usersView")) return;

      switch (index) {
        case 1:
          router.push({
            name: "protected-users",
            query: {
              hasVulnerabilities: "true",
              widget: TicketsModuleFilter.CLOUD_SECURITY_WIDGET,
            },
          });
          break;
        default:
          router.push({
            name: "protected-users",
            query: {
              hasVulnerabilities: "false",
              widget: TicketsModuleFilter.CLOUD_SECURITY_WIDGET,
            },
          });
      }
    };

    return {
      coronetIcons: CoroIcons,
      serviceStatuses: ServiceStatus,
      ticketsWidgetFilters: TicketsModuleFilter,
      rolePermissionsScope: RolePermissionsScope,
      workspaceManagementScope: WorkspaceManagementScopeSections,
      showWidgetData,
      chartData,
      isAccessRestricted,
      onChartClick,
      kebabCase,
      viewAllTickets,
      viewVulnerability,
      RouteName,
    };
  },
});
</script>

<style lang="scss" scoped>
.cloud-app-icon {
  height: 48px;
  width: 48px;
}
</style>
