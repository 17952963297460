<template>
  <DoughnutChart
    ref="chartRef"
    :data="data"
    :options="optionsWithDefaults"
    @click="handleChartClick"
  />
</template>

<script lang="js">
import { defineComponent, ref } from "vue";
import { Doughnut, getElementsAtEvent } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import defaultsDeep from "lodash/defaultsDeep";
import { COLORS } from "@/constants/colors.ts";

ChartJS.register({
  id: "doughnutCenterText",
  beforeDraw(chart) {
    const { width } = chart;
    const { top, bottom } = chart.chartArea;
    const ctx = chart.ctx;

    if (chart.config.options.insideTextData) {
      const { title, subtitle } = chart.config.options.insideTextData;

      // Define the center coordinates
      const x = width / 2;
      const y = (top + bottom) / 2;

      // Draw the main number (e.g., "1234")
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = COLORS.PRIMARY;
      ctx.font = "500 48px Quicksand";
      ctx.fillText(title, x, y - 20); // Adjust positioning (move up slightly)

      // Draw the subtext with word wrapping
      ctx.fillStyle = COLORS.PRIMARY; // Subtext color
      ctx.font = "600 16px Source Sans Pro"; // Subtext font
      const words = subtitle.split(" ");
      const lineHeight = 20; // Line height for the subtext
      let line = "";
      const lines = [];
      const maxWidth = 150; // Max width for text wrapping

      // Handle word wrapping
      words.forEach((word) => {
        const testLine = line + word + " ";
        const testWidth = ctx.measureText(testLine).width;
        if (testWidth > maxWidth) {
          lines.push(line);
          line = word + " ";
        } else {
          line = testLine;
        }
      });
      lines.push(line); // Add the last line

      // Draw each line below the main text
      lines.forEach((line, index) => {
        ctx.fillText(line.trim(), x, y + 20 + index * lineHeight);
      });
    }
  },
});

// Register Chart.js modules
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

export default defineComponent({
  components: {
    DoughnutChart: Doughnut,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const chartRef = ref(null);
    const defaultOptions = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label(tooltipItem) {
              return tooltipItem.label;
            },
            title: () => null,
          },
          caretSize: 0,
          backgroundColor: COLORS.PRIMARY,
          bodyFontColor: COLORS.WHITE,
          bodyFontSize: 14,
          displayColors: false,
        },
      },
      layout: {
        padding: 10,
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      hoverOffset: 5,
      onHover: (event, activeElements) => {
        if (activeElements?.length > 0) {
          event.native.target.style.cursor = "pointer";
        } else {
          event.native.target.style.cursor = "auto";
        }
      },
    };

    const handleChartClick = (event) => {
      const {
        value: { chart },
      } = chartRef;

      if (!chart) {
        return;
      }

      emit("on-chart-click", getElementsAtEvent(chart, event)[0].index);
    };

    return {
      chartRef,
      handleChartClick,
      optionsWithDefaults: defaultsDeep(props.options, defaultOptions),
    };
  },
});
</script>
