<template>
  <v-expansion-panel expand-icon="" collapse-icon="" class="mb-4" :class="{ error: !valid }">
    <v-expansion-panel-title class="direction-vertical">
      <template #default="{ expanded }">
        <div class="d-flex align-center w-100">
          <v-icon
            class="mr-2 cursor-pointer"
            icon="$dropdown"
            color="primary"
            :class="{ rotated: expanded }"
          />
          <span class="subtitle2">{{ $t("modals.addZtnaRule.instance", { index }) }}</span>
          <v-spacer />
          <v-icon
            v-if="showRemoveBtn"
            size="24"
            role="button"
            icon="$x"
            @click.stop="removeInstance"
          />
        </div>
        <div v-if="!expanded && localValue.value" class="d-flex summary-container align-center">
          <span class="caption text-indigo-medium mr-4">{{ getResourceTypeDisplay }}</span>
          <span
            v-if="showPorts && localValue.ports && localValue.ports.length"
            class="caption text-indigo-medium mr-4"
          >
            {{ `${$t("general.port")}: ${localValue.ports.join(", ")}` }}
          </span>
          <span v-if="showPorts && localValue.protocol" class="caption text-indigo-medium mr-4">
            {{
              `${$t("general.protocol")}: ${$t(`network.virtualOffice.ztna.protocols.${localValue.protocol}`)}`
            }}
          </span>
        </div>
      </template>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-select
        v-model="selectedMode"
        class="w-30 mb-4"
        density="compact"
        variant="outlined"
        item-value="name"
        item-title="displayName"
        :items="resourceTypes"
        rounded
      >
        <template #selection="{ item }">
          <span class="body2">
            {{ $t(`modals.addZtnaRule.resourceTypes.${item.value}`) }}
          </span>
        </template>
      </v-select>
      <template v-if="isSingleIp">
        <v-text-field
          v-model.trim="localValue.value"
          variant="outlined"
          :rules="[required(), ipAddressRule]"
          :label="$t('general.ipAddress')"
          class="mb-4"
        />
      </template>
      <template v-else-if="isIpRange">
        <v-row class="mb-4">
          <v-col class="key-part" :cols="6">
            <v-text-field
              v-model.trim="ipRangeStart"
              variant="outlined"
              :rules="[required(), ipAddressRule, validateIpRange('ipStartRangeIsIncorrect')]"
              :label="$t('modals.addZtnaRule.placeholders.startIpAddress')"
              @blur="$emit('validate')"
            />
          </v-col>
          <v-col class="value-part" :cols="6">
            <v-text-field
              v-model.trim="ipRangeEnd"
              variant="outlined"
              :rules="[required(), ipAddressRule, validateIpRange('ipEndRangeIsIncorrect')]"
              :label="$t('modals.addZtnaRule.placeholders.endIpAddress')"
              @blur="$emit('validate')"
            />
          </v-col>
        </v-row>
      </template>
      <template v-else-if="isIpAndSubnet">
        <v-text-field
          v-model.trim="localValue.value"
          variant="outlined"
          :rules="[required(), cidrRule]"
          :label="$t(`modals.addZtnaRule.placeholders.${localValue.type.toLowerCase()}`)"
          class="mb-4"
        />
      </template>
      <template v-else-if="isDomain">
        <v-text-field
          v-model.trim="localValue.value"
          variant="outlined"
          :rules="[required(), domainRule]"
          :label="$t('general.domain')"
          class="mb-4"
        />
      </template>
      <template v-if="showPorts">
        <v-row>
          <v-col :cols="8">
            <v-combobox
              ref="portsCombobox"
              v-model="localValue.ports"
              v-model:search="currentInputValue"
              class="ports-input mr-2"
              :label="$t('modals.addZtnaRule.placeholders.ports', { max: 5 })"
              :placeholder="$t('modals.addZtnaRule.placeholders.enterUpToPorts', { max: 5 })"
              variant="outlined"
              multiple
              :rules="[portsRule]"
              chips
              no-filter
              hide-no-data
              @update:search="handleCommaDelimiterPaste"
              @blur="handleBlur"
            >
              <template #chip="{ props, item }">
                <v-chip
                  v-bind="props"
                  variant="flat"
                  closable
                  close-icon="$closeCircle"
                  :color="getChipColor(item.raw)"
                >
                  <div class="d-flex align-center justify-space-between">
                    <span class="ml-1 mr-2" v-text="item.raw" />
                  </div>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col :cols="4">
            <v-select
              v-model="localValue.protocol"
              :items="protocols"
              variant="outlined"
              :rules="[required()]"
              :label="$t('general.protocol')"
              item-value="name"
              item-title="displayName"
            >
              <template #selection="{ item }">
                <span class="body2">
                  {{ $t(`network.virtualOffice.ztna.protocols.${item.value}`) }}
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </template>
      <div v-if="rootErrorMessage" class="caption text-red">
        {{ rootErrorMessage }}
      </div>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, type PropType, type Ref, ref, watch } from "vue";
import type { RuleConfig } from "@/_store/network/ztna.module";
import { required } from "@/_helpers/validators";
import Patterns from "@/constants/patterns";
import { ConfigType, Protocol } from "@/constants/network";

import { useI18n } from "vue-i18n";
import { hasSeparator } from "@/_helpers/utils";
import trim from "lodash/trim";
import uniq from "lodash/uniq";
import { i18n } from "@/plugins/i18n.ts";
import type { ErrorPayload } from "@/_store/errors.module.ts";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<RuleConfig>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    showRemoveBtn: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Array as PropType<ErrorPayload[]>,
      default() {
        return [];
      },
    },
    rulesCount: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:localValue", "removeInstance", "update:errors", "validate"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const localValue = ref({
      ...props.config,
    });
    const currentInputValue = ref("");
    const portsCombobox = ref();
    const ipRangeStart = ref("");
    const ipRangeEnd = ref("");
    const selectedMode: Ref<string> = ref("");
    const previousDomain = ref("");
    const domainErrorMessage = ref("");

    const resourceTypes = [
      {
        name: ConfigType.IP,
        displayName: t(`modals.addZtnaRule.resourceTypes.${ConfigType.IP}`),
      },
      {
        name: ConfigType.CIDR,
        displayName: t(`modals.addZtnaRule.resourceTypes.${ConfigType.CIDR}`),
      },
      {
        name: ConfigType.RANGE,
        displayName: t(`modals.addZtnaRule.resourceTypes.${ConfigType.RANGE}`),
      },
      {
        name: ConfigType.DOMAIN,
        displayName: t(`modals.addZtnaRule.resourceTypes.${ConfigType.DOMAIN}`),
      },
    ];

    const protocols = [
      {
        name: Protocol.TCP,
        displayName: t(`network.virtualOffice.ztna.protocols.${Protocol.TCP}`),
      },
      {
        name: Protocol.UDP,
        displayName: t(`network.virtualOffice.ztna.protocols.${Protocol.UDP}`),
      },
      {
        name: Protocol.ALL,
        displayName: t(`network.virtualOffice.ztna.protocols.${Protocol.ALL}`),
      },
    ];

    const isSingleIp = computed(() => localValue.value.type === ConfigType.IP);
    const isIpRange = computed(() => localValue.value.type === ConfigType.RANGE);
    const isIpAndSubnet = computed(() => localValue.value.type === ConfigType.CIDR);
    const isDomain = computed(() => localValue.value.type === ConfigType.DOMAIN);

    const showPorts = computed(() => {
      return [ConfigType.IP, ConfigType.RANGE, ConfigType.CIDR].includes(localValue.value.type);
    });

    const valid = computed(() => {
      return props.errors.length === 0;
    });

    const rootErrorMessage = computed(() => {
      if (valid.value) return;
      return props.errors?.find((e) => typeof !e.field)?.message;
    });

    const domainError = computed(() => {
      return props.errors.find((e: ErrorPayload) => e.field === "value");
    });

    const ipAddressRule = (value: string) => {
      if (!value) return t("validations.required");
      if (!Patterns.IP_WITHOUT_CIDR.test(value)) {
        return t("validations.ipAddress");
      }
      return true;
    };

    const cidrRule = (value: string) => {
      if (!value) return t("validations.required");
      if (!Patterns.IP_WITH_REQUIRED_CIDR.test(value)) {
        return t("validations.cidr");
      }
      return true;
    };

    const domainRule = (value: string) => {
      if (!value) return t("validations.required");
      if (!Patterns.DOMAIN_WITH_UNICODE_SUPPORT.test(value)) {
        return t("validations.domain");
      }
      if (Patterns.NOT_ALLOWED_DOMAIN_ZONE.test(value)) {
        return i18n.global.t("validations.domainZoneIsNotAllowed");
      }
      if (domainError.value || previousDomain.value === value) {
        previousDomain.value = value;
        domainErrorMessage.value = domainError.value?.message || domainErrorMessage.value;
        emit("update:errors");
        return domainErrorMessage.value;
      }
      return true;
    };

    const portsRule = (value: string[]) => {
      if (!value || !value.length) return t("validations.required");

      if (value.some((port: string) => !Patterns.PORT.test(port))) {
        return t("validations.port");
      }

      if (value.length > 5) return t("validations.maxPorts", { n: 5 });

      return true;
    };

    const handleBlur = () => {
      if (!currentInputValue.value) return;
      const hasCommaSeparator = hasSeparator(currentInputValue.value);
      if (hasCommaSeparator) {
        currentInputValue.value = "";
      }
    };

    const handleCommaDelimiterPaste = (newVal: string) => {
      const hasCommaSeparator = hasSeparator(newVal);
      if (!hasCommaSeparator) return;
      const ports = newVal
        .split(",")
        .filter((item: string) => item)
        .map((e) => trim(e))
        .filter((port) => Patterns.PORT.test(port));

      if (!localValue.value.ports || !Array.isArray(localValue.value.ports)) {
        localValue.value.ports = [];
      }

      localValue.value.ports = uniq([...localValue.value.ports, ...ports]);
      currentInputValue.value = "";
      if (portsCombobox.value) {
        portsCombobox.value.search = "";
      }
    };

    const removeInstance = () => {
      emit("removeInstance");
    };

    const getChipColor = (port: string) => {
      const isPortValid = Patterns.PORT.test(port);
      return isPortValid ? "indigo-faint" : "error";
    };

    const validateIpRange = (errorCode: string) => {
      if (!ipRangeStart.value || !ipRangeEnd.value) return true;

      const startParts = ipRangeStart.value.split(".").map(Number);
      const endParts = ipRangeEnd.value.split(".").map(Number);

      for (let i = 0; i < 4; i++) {
        if (startParts[i] < endParts[i]) {
          return true;
        }
        if (startParts[i] > endParts[i]) {
          return t(`validations.${errorCode}`);
        }
      }
      // If we get here, the IPs are equal
      return t(`validations.${errorCode}`);
    };

    onMounted(() => {
      selectedMode.value = props.config.type;
    });

    watch(selectedMode, (newVal, oldValue) => {
      if (oldValue && newVal !== oldValue) {
        localValue.value = {
          ...localValue.value,
          type: newVal as ConfigType,
          value: "",
        };

        if (newVal === ConfigType.DOMAIN) {
          localValue.value.ports = [];
          localValue.value.protocol = null;
        }
        emit("update:localValue", localValue.value);
      }
    });

    watch(
      localValue,
      () => {
        emit("update:localValue", localValue.value);
        if (props.errors.length && !valid.value && !domainError.value) {
          emit("update:errors");
        }
      },
      { deep: true }
    );

    watch(ipRangeStart, (val) => {
      if (isIpRange.value) {
        localValue.value.value = val + "-" + ipRangeEnd.value;
        emit("update:localValue", localValue.value);
      }
    });

    watch(ipRangeEnd, (val) => {
      if (isIpRange.value) {
        localValue.value.value = ipRangeStart.value + "-" + val;
        emit("update:localValue", localValue.value);
      }
    });

    watch(
      localValue,
      () => {
        if (isIpRange.value && localValue.value.value) {
          const parts = localValue.value.value.split("-");
          if (parts.length === 2) {
            ipRangeStart.value = parts[0];
            ipRangeEnd.value = parts[1];
          }
        }
      },
      { immediate: true, deep: true }
    );

    watch(
      () => props.rulesCount,
      () => {
        localValue.value = {
          ...props.config,
        };
      }
    );

    const getResourceTypeDisplay = computed(() => {
      let value = props.config.value;
      if (isIpRange.value) {
        value = value.replace("-", " - ");
      }

      return `${t(`network.virtualOffice.ztna.resourceTypes.${localValue.value.type}`)}: ${value}`;
    });

    return {
      protocols,
      resourceTypes,
      isSingleIp,
      isIpRange,
      isIpAndSubnet,
      isDomain,
      localValue,
      ConfigType,
      selectedMode,
      currentInputValue,
      handleCommaDelimiterPaste,
      portsCombobox,
      required,
      ipRangeStart,
      ipRangeEnd,
      portsRule,
      handleBlur,
      ipAddressRule,
      cidrRule,
      domainRule,
      showPorts,
      removeInstance,
      getResourceTypeDisplay,
      getChipColor,
      valid,
      rootErrorMessage,
      validateIpRange,
    };
  },
});
</script>

<style scoped lang="scss">
.ports-input {
  flex: 3;
}

.w-30 {
  width: 30%;
}

.summary-container {
  margin-left: 30px;
}

.v-expansion-panel {
  border: 1px solid rgb(var(--v-theme-indigo-pale));
  border-radius: 8px !important;
}

.v-expansion-panel.error {
  border: 1px solid rgb(var(--v-theme-error));
}

.v-expansion-panel-title {
  flex-direction: column !important;
  align-items: flex-start !important;
}

:deep(.key-part) {
  .v-field {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .v-field__outline__end {
    border-right: none;
  }

  .v-field:hover {
    .v-field__outline__end {
      border-right: 1px solid rgb(var(--v-theme-primary));
    }
  }

  .v-field--error {
    .v-field__outline__end {
      border-right: 2px solid rgb(var(--v-theme-red-dark));
    }
  }

  .v-field--focused:not(.v-field--error) {
    .v-field__outline__end {
      border-right: 2px solid rgb(var(--v-theme-primary));
    }
  }
}

:deep(.value-part) {
  .v-field {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

:deep(*) {
  .key-part {
    padding: 0 0 0 12px;

    .error--text fieldset {
      border-right: 1px solid rgb(var(--v-theme-red-dark)) !important;
    }

    .v-input--is-focused:not(.error--text) fieldset {
      border-right-width: 1px solid rgb(var(--v-theme-primary)) !important;
    }
  }
  .value-part {
    padding: 0 12px 0 0;
  }

  .v-expansion-panel-title__icon {
    display: none !important;
  }
}
</style>
