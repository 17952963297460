import type { AgentGradualUpdateData } from "@/_store/back-office/back-office.module";
import type {
  GetDeviceAllowBlockListParameters,
  GetEdrProcessesListParams,
  GetEdrTelemetryListParams,
  GetProcessGraphParams,
  GetUsersRequestParameters,
  UnixTimeFrame,
} from "@/_helpers/api.types";
import type { Pagination } from "@/types";
import type { DeviceAllowBlockListItem } from "@/_store/endpoint-security/device-allow-block-list.module";
import type { RolePermissions } from "@/_store/roles.module";
import type { Service } from "@/constants/cloud-apps";
import type { DnsReportReload } from "@/_store/reports.module";
import type { SecureWebGatewaySettings } from "@/_store/secure-web-gateway.module";
import type { VirtualOfficeSettings } from "@/_store/network/virtual-office.module";
import type { ProcessGraphAction } from "@/constants/edr";
import type { AxiosRequestConfig } from "axios";

const baseURL = import.meta.env.VITE_API_HOST;

export default {
  login: {
    url: "/login",
    baseURL,
    method: "post",
  },
  socialSignUp: () => {
    return {
      url: "/sign-up/social",
      baseURL,
      method: "POST",
    };
  },
  acceptInvite: {
    url: "/admin-users/joinWorkspace",
    baseURL,
    method: "post",
  },
  socialLogin: (service: Service, brandingAlias: string | undefined = undefined) => {
    return {
      url: "/login",
      baseURL,
      method: "post",
      params: {
        service,
        brandingAlias,
      },
    };
  },
  promoCodes: () => {
    return {
      url: "/backoffice/promo-codes",
      baseURL,
    };
  },
  deactivatePromoCode: (id: string) => {
    return {
      url: `/backoffice/promo-codes/deactivate/${id}`,
      baseURL,
      method: "put",
    };
  },
  deletePromoCode: (id: string) => {
    return {
      url: `/backoffice/promo-codes/${id}`,
      baseURL,
      method: "delete",
    };
  },
  activatePromoCode: (id: string) => {
    return {
      url: `/backoffice/promo-codes/activate/${id}`,
      baseURL,
      method: "put",
    };
  },
  searchPromoCodeSalesAdministrators: (search: string = "") => {
    return {
      url: `/backoffice/promo-codes/sales`,
      baseURL,
      params: {
        search,
      },
    };
  },
  searchPromoCodeChannelWorkspaces: (search: string, { page, pageSize }: Pagination) => {
    return {
      url: `/backoffice/promo-codes/channel-workspaces`,
      baseURL,
      params: {
        search,
        page,
        pageSize,
      },
    };
  },
  exportPromoCodesCsv: {
    url: "/backoffice/promo-codes/csv/export",
    baseURL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  },
  logout: () => {
    return {
      url: `/logout`,
      baseURL,
    };
  },
  refreshToken: () => {
    return {
      url: "/refreshToken",
      baseURL,
    };
  },
  adminUsers: {
    url: "/admin-users",
    baseURL,
  },
  adminUser: (id: string) => {
    return {
      url: `/admin-users/${id}`,
      baseURL,
    };
  },
  adminUserContentInspectionPermissions: (id: string) => {
    return {
      url: `/admin-users/${id}/content-inspection-permissions`,
      baseURL,
    };
  },
  getPsaGradientConnector: () => ({
    url: "/settings/connectors/gradient",
    baseURL,
  }),
  getPsaConnectwiseConnector: () => ({
    url: "/settings/connectors/connectwise",
    baseURL,
  }),
  getPsaAutotaskConnector: () => ({
    url: "/settings/connectors/autotask",
    baseURL,
  }),
  connectwiseWorkspacesMapping: () => ({
    url: "/settings/connectors/connectwise/mapping/workspaces",
    baseURL,
  }),
  connectwiseCompanies: () => ({
    url: "/settings/connectors/connectwise/mapping/companies",
    baseURL,
  }),
  connectwiseServicesMapping: () => ({
    url: "/settings/connectors/connectwise/mapping/services",
    baseURL,
  }),
  connectwiseProducts: () => ({
    url: "/settings/connectors/connectwise/mapping/products",
    baseURL,
  }),
  siemConnectors: () => ({
    url: "/settings/connectors/siem",
    baseURL,
  }),
  siemConnector: (id: string) => ({
    url: `/settings/connectors/siem/${id}`,
    baseURL,
  }),
  syncSiemConnector: () => ({
    url: "/settings/connectors/siem/sync",
    baseURL,
  }),
  webhooks: () => ({
    url: "/settings/connectors/webhooks",
    baseURL,
  }),
  webhook: (id: string) => ({
    url: `/settings/connectors/webhooks/${id}`,
    baseURL,
  }),
  updateWebhook: () => ({
    url: `/settings/connectors/webhook`,
    baseURL,
  }),
  testWebhook: (id: string) => ({
    url: `/settings/connectors/webhooks/${id}/test`,
    baseURL,
  }),
  webhookTriggers: () => ({
    url: "/settings/connectors/webhooks/availableTriggers",
    baseURL,
  }),
  connector: (id: string) => ({
    url: `/settings/connectors/${id}`,
    baseURL,
  }),
  autotaskWorkspacesMapping: () => ({
    url: "/settings/connectors/autotask/mapping/workspaces",
    baseURL,
  }),
  autotaskCompanies: () => ({
    url: "/settings/connectors/autotask/mapping/companies",
    baseURL,
  }),
  autotaskServicesMapping: () => ({
    url: "/settings/connectors/autotask/mapping/services",
    baseURL,
  }),
  autotaskProducts: () => ({
    url: "/settings/connectors/autotask/mapping/products",
    baseURL,
  }),
  resendInvite: (id: string) => {
    return {
      url: `/admin-users/${id}/resendInvite`,
      baseURL,
      method: "post",
    };
  },
  generateInviteLink: (id: string) => {
    return {
      url: `/admin-users/${id}/generateInviteLink`,
      baseURL,
      method: "post",
    };
  },
  myAccount: {
    url: "/portal/account",
    baseURL,
  },
  changePassword: {
    url: "/portal/account/changePassword",
    baseURL,
  },
  getNotificationsSettings: {
    url: "/portal/account/notifications",
    baseURL,
    method: "GET",
  },
  getActivityLogs: () => {
    return {
      url: "/activityLogs",
      baseURL,
    };
  },
  activityLogsUndo: (id: string) => ({
    method: "POST",
    url: "/activityLogs/undo",
    baseURL,
    data: {
      id,
    },
  }),
  activityLogsExportCSV: {
    method: "POST",
    url: "/activityLogs/export/csv",
    baseURL,
  },
  dataLossPermissions: () => ({
    url: "/settings/dataLoss/dataPolicies",
    baseURL,
  }),
  monitoredDataPolicies: () => ({
    url: "/settings/dataLoss/monitoredDataPolicies",
    baseURL,
  }),
  userDataGovernanceSettings: () => ({
    url: "/settings/dataLoss/monitoring",
    baseURL,
  }),
  privacyParametersConfig: () => ({
    url: "/settings/dataGovernance/monitoring/config",
    baseURL,
  }),
  userDataGovernanceExclusions: () => ({
    url: "/settings/dataLoss/exclusions",
    baseURL,
  }),
  securityTrainingSettings: () => ({
    url: "/security-awareness/settings",
    baseURL,
  }),
  phishingSimulations: () => ({
    url: "/settings/security-awareness/phishing/simulations",
    baseURL,
  }),
  exportPhishingSimulationToCsv: () => {
    return {
      url: "/phishing-simulation/export/csv",
      baseURL,
      method: "POST",
    };
  },
  securityAwarenessEmailTemplate: () => ({
    url: "/settings/security-awareness/email-template",
    baseURL,
  }),
  sendSecurityAwarenessTestEmails: () => {
    return {
      url: "/security-awareness/settings/email/test",
      method: "POST",
      baseURL,
    };
  },
  confirmSecurityAwarenessEmails: () => {
    return {
      url: "/security-awareness/settings/email/confirm",
      method: "POST",
      baseURL,
    };
  },
  securityAwarenessMetadata: () => {
    return {
      url: "/security-awareness/settings/metadata",
      baseURL,
    };
  },
  dismissSaBanner: () => {
    return {
      url: "/security-awareness/settings/dismiss-banner",
      method: "PUT",
      baseURL,
    };
  },
  getBackOfficeData: ({ fromTime, toTime }: UnixTimeFrame) => {
    return {
      url: "/backoffice",
      baseURL,
      params: {
        fromTime,
        toTime,
      },
    };
  },
  globalAdminUsers: {
    url: "/admin-users/global",
    baseURL,
  },
  globalAdminUser: (id: string) => {
    return {
      url: `/admin-users/global/${id}`,
      baseURL,
    };
  },
  globalAdminUsersCandidates: () => {
    return {
      url: "/admin-users/global/candidates",
      baseURL,
    };
  },
  getWorkspacePermissions: (workspaceId: string) => {
    return {
      url: `/workspaces/${workspaceId}/permissions`,
      baseURL,
    };
  },
  getMspPermissions: () => {
    return {
      url: `/workspaces/msp/permissions`,
      baseURL,
    };
  },
  getGlobalPermissions: () => {
    return {
      url: `/workspaces/global/permissions`,
      baseURL,
    };
  },
  getComments: ({ eventId, page = 0, pageSize = 5 }: { eventId: string } & Pagination) => {
    return {
      url: `events/${eventId}/comments`,
      baseURL,
      method: "GET",
      params: {
        page,
        pageSize,
      },
    };
  },
  addComment: (eventId: string) => {
    return {
      url: `/events/${eventId}/comments`,
      baseURL,
      method: "POST",
    };
  },
  apiCredentials: () => ({
    url: "/oauth/credentials",
    baseURL,
  }),
  getUserInfo: (userId: string) => {
    return {
      url: `/dashboard/users/${userId}`,
      baseURL,
      method: "GET",
    };
  },
  getUserTickets: (userId: string) => {
    return {
      url: `/dashboard/users/${userId}/tickets`,
      baseURL,
      method: "GET",
    };
  },
  getUserActivityLogs: (userId: string) => {
    return {
      url: `/dashboard/users/${userId}/activityLogs`,
      baseURL,
      method: "GET",
    };
  },
  protectedUsersAction: {
    url: "/dashboard/users/action",
    baseURL,
    method: "POST",
  },
  usersListActions: {
    url: "/dashboard/users/available/action",
    baseURL,
    method: "POST",
  },
  availableDeviceActions: {
    url: "/devices/available/actions",
    baseURL,
    method: "POST",
  },
  availableMspDeviceActions: {
    url: "/msp/devices/available-actions",
    baseURL,
    method: "POST",
  },
  getMonitoringSettingsByWorkspace: {
    url: "/msp/devices/settings/endpointDataGovernance/monitoring",
    baseURL,
  },
  getDeviceSettingsByWorkspace: {
    url: "/msp/devices/settings/workspace-device-settings",
    baseURL,
  },
  getUsers: ({
    page,
    pageSize,
    hasVulnerabilities,
    vulnerabilityState,
    vulnerability,
    search,
    widget,
  }: GetUsersRequestParameters) => {
    const params = {
      page,
      pageSize,
      hasVulnerabilities,
      vulnerabilityState,
      vulnerability,
      search,
      widget,
    };

    return {
      url: "/dashboard/users",
      baseURL,
      params,
    };
  },
  getWorkspaceMFAConfig: {
    url: "/workspaces/mfaConfig",
    baseURL,
    method: "GET",
  },
  toggleWorkspaceMFA: {
    url: "/workspaces/updateMfaConfig",
    baseURL,
    method: "PUT",
  },
  resetMFAConfig: {
    url: "/mfa/sms-code",
    baseURL,
    method: "POST",
  },
  deleteMFA: (email: string) => {
    return {
      url: `/portal/account/${email}/mfa`,
      baseURL,
      method: "DELETE",
    };
  },
  getCurrentWorkspace: {
    url: "/workspaces/current",
    baseURL,
  },
  endpointDataGovernanceSettings: {
    url: "/settings/endpointDataGovernance/monitoring",
    baseURL,
  },
  sensitiveDataScans: {
    url: "/settings/endpointDataGovernance/sensitiveDataScans",
    baseURL,
  },
  searchLabels: (search: string) => {
    return {
      url: "/devices/labels",
      baseURL,
      params: {
        search,
      },
    };
  },
  getSocContacts: {
    url: "/settings/soc",
    baseURL,
  },
  editSocContacts: (id: string) => {
    return {
      url: `/settings/soc`,
      baseURL,
      method: "PUT",
      params: {
        id,
      },
    };
  },
  socRemediation: {
    url: "/settings/soc/remediation",
    baseURL,
  },
  workplaces: ({
    name,
    page,
    pageSize,
    type,
  }: {
    name: string;
    page: number;
    pageSize: number;
    type?: string;
  }) => {
    return {
      url: "/workspaces",
      baseURL,
      params: {
        name,
        page,
        pageSize,
        type,
      },
    };
  },
  pendingInvitesToWorkplace: (name: string, pagination: Pagination) => {
    return {
      url: "/workspaces/pendingInvites",
      baseURL,
      params: {
        name,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    };
  },
  joinWorkplace: (id: string) => {
    return {
      url: `/workspaces/join/${id}`,
      baseURL,
      method: "post",
    };
  },
  getDeviceAllowBlocklist: ({
    type,
    search,
    page,
    pageSize,
  }: GetDeviceAllowBlockListParameters) => {
    return {
      url: "/settings/devices/allowBlockList",
      baseURL,
      params: {
        types: type,
        search,
        page,
        pageSize,
      },
    };
  },
  deleteDeviceAllowBlocklist: (id: string) => {
    return {
      url: `/settings/devices/allowBlockList/${id}`,
      baseURL,
      method: "DELETE",
    };
  },
  deviceAllowBlockListInheritanceSettings: {
    url: "/settings/devices/allowBlockList/inheritanceEnabled",
    baseURL,
  },
  addDeviceAllowBlockList: (data: DeviceAllowBlockListItem) => {
    return {
      url: "/settings/devices/allowBlockList",
      baseURL,
      method: "POST",
      data,
    };
  },
  uploadEdrAllowBlockListCSV: (data: FormData) => {
    return {
      url: "/settings/devices/allowBlockList/upload",
      baseURL,
      method: "POST",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  },
  edrAllowBlockListCSVTemplate: {
    url: "/settings/devices/allowBlockList/upload/template",
    baseURL,
  },
  getExecutiveReport: () => {
    return {
      url: "/summary-reports/workspace",
      baseURL,
    };
  },
  getDnsReport: ({ fromTime, toTime, refresh }: DnsReportReload) => {
    return {
      url: "/settings/addons/network/swg/dns/report",
      baseURL,
      params: {
        fromTime,
        toTime,
        refresh,
      },
    };
  },
  getManagedServicesReport: ({ fromTime, toTime }: UnixTimeFrame) => {
    return {
      url: "/summary-reports/soc",
      baseURL,
      params: {
        fromTime,
        toTime,
      },
    };
  },

  getPhishingSimulationReport: ({ fromTime, toTime }: UnixTimeFrame) => {
    return {
      url: "/summary-reports/security-awareness/simulations",
      baseURL,
      params: {
        fromTime,
        toTime,
      },
    };
  },
  exportPhishingSimulationReportToCsv: () => {
    return {
      url: "/phishing-simulation/export/csv",
      baseURL,
      method: "POST",
    };
  },
  getSecuredMessagesReport: ({ fromTime, toTime }: UnixTimeFrame) => {
    return {
      url: "/summary-reports/secured-messages",
      baseURL,
      params: {
        fromTime,
        toTime,
      },
    };
  },
  getSecurityAwarenessReport: ({ fromTime, toTime }: UnixTimeFrame) => {
    return {
      url: "/summary-reports/security-awareness/trainings",
      baseURL,
      params: {
        fromTime,
        toTime,
      },
    };
  },
  exportSecurityTrainingToCsv: () => {
    return {
      url: "/summary-reports/trainings/export/csv",
      baseURL,
      method: "POST",
    };
  },
  uploadPdfReport: {
    url: "/summary-reports/upload",
    baseURL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    method: "POST",
  },
  getRolesList: () => {
    return {
      url: "/roles",
      baseURL,
      method: "GET",
    };
  },
  getRolePermissions: (id: string) => {
    return {
      url: `/roles/${id}/permissions`,
      baseURL,
      method: "GET",
    };
  },
  getRoleAssignees: (id: string, { page, pageSize }: Pagination) => {
    return {
      url: `/roles/${id}/assignees`,
      baseURL,
      method: "GET",
      params: {
        page,
        pageSize,
      },
    };
  },
  createNewRole: (data: RolePermissions) => {
    return {
      url: `/roles`,
      baseURL,
      method: "POST",
      data,
    };
  },
  deleteRole: (id: string) => {
    return {
      url: `/roles/${id}`,
      baseURL,
      method: "DELETE",
    };
  },
  editRole: (id: string, data: RolePermissions) => {
    return {
      url: `/roles/${id}`,
      baseURL,
      method: "PUT",
      data,
    };
  },
  searchRoles: () => {
    return {
      url: "/roles/search",
      baseURL,
      method: "GET",
    };
  },
  getMspRolesList: () => {
    return {
      url: "/roles/msp",
      baseURL,
      method: "GET",
    };
  },
  getMspRolePermissions: (id: string) => {
    return {
      url: `/roles/msp/${id}/permissions`,
      baseURL,
      method: "GET",
    };
  },
  getMspRoleAssignees: (id: string, { page, pageSize }: Pagination) => {
    return {
      url: `/roles/msp/${id}/assignees`,
      baseURL,
      method: "GET",
      params: {
        page,
        pageSize,
      },
    };
  },
  createNewMspRole: (data: RolePermissions) => {
    return {
      url: `/roles/msp`,
      baseURL,
      method: "POST",
      data,
    };
  },
  deleteMspRole: (id: string) => {
    return {
      url: `/roles/msp/${id}`,
      baseURL,
      method: "DELETE",
    };
  },
  editMspRole: (id: string, data: RolePermissions) => {
    return {
      url: `/roles/msp/${id}`,
      baseURL,
      method: "PUT",
      data,
    };
  },
  searchMspRoles: () => {
    return {
      url: "/roles/msp/search",
      baseURL,
      method: "GET",
    };
  },
  getGlobalRolesList: () => {
    return {
      url: "/roles/global",
      baseURL,
      method: "GET",
    };
  },
  getGlobalRolePermissions: (id: string) => {
    return {
      url: `/roles/global/${id}/permissions`,
      baseURL,
      method: "GET",
    };
  },
  getGlobalRoleAssignees: (id: string, { page, pageSize }: Pagination) => {
    return {
      url: `/roles/global/${id}/assignees`,
      baseURL,
      method: "GET",
      params: {
        page,
        pageSize,
      },
    };
  },
  createNewGlobalRole: (data: RolePermissions) => {
    return {
      url: `/roles/global`,
      baseURL,
      method: "POST",
      data,
    };
  },
  deleteGlobalRole: (id: string) => {
    return {
      url: `/roles/global/${id}`,
      baseURL,
      method: "DELETE",
    };
  },
  editGlobalRole: (id: string, data: RolePermissions) => {
    return {
      url: `/roles/global/${id}`,
      baseURL,
      method: "PUT",
      data,
    };
  },
  searchGlobalRoles: () => {
    return {
      url: "/roles/global/search",
      baseURL,
      method: "GET",
    };
  },
  emailsSettings: () => ({
    url: "/settings/emails",
    baseURL,
  }),
  resetEmailFileTypes: () => ({
    url: "/settings/emails/resetFileTypes",
    baseURL,
  }),
  emailProxySettings: () => ({
    url: "/settings/proxySettings",
    baseURL,
  }),
  emailProxyDomains: () => {
    return {
      url: "/settings/emailProxy",
      baseURL,
    };
  },
  editEmailProxyDomain: (id: string) => {
    return {
      url: `/settings/emailProxy/${id}`,
      baseURL,
    };
  },
  testEmailProxyDomain: (id: string) => {
    return {
      url: `/settings/emailProxy/${id}/testProxy`,
      baseURL,
      method: "POST",
    };
  },
  completeEmailProxyTest: (proxyDomainId: string) => {
    return {
      url: `/emailProxy/${proxyDomainId}/testProxy/check`,
      baseURL,
      method: "POST",
    };
  },
  getEmailProxyStatus: () => {
    return {
      url: "/settings/emailProxy/proxyStatus",
      baseURL,
    };
  },
  getNgavSettings: () => ({
    url: "/settings/devices/ngav",
    baseURL,
  }),
  importAllowBlockList: () => {
    return {
      url: "/settings/phishing/list/csv",
      baseURL,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  },
  getPhishingAllowBlackListExampleCsv: () => {
    return {
      url: "/settings/phishing/list/csv/example",
      baseURL,
    };
  },
  getPhishingAllowBlackList: () => {
    return {
      url: "/settings/phishing/list",
      baseURL,
    };
  },
  getDevicesWifiPhishingSettings: () => ({
    url: "/settings/devices/wifiPhishing",
    baseURL,
  }),
  getActiveSessions({ page, pageSize }: Pagination) {
    return {
      url: "/sessions",
      baseURL,
      params: {
        page,
        pageSize,
      },
    };
  },
  revokeSession: (sessionId: string) => {
    return {
      url: `/sessions/revoke/${sessionId}`,
      baseURL,
      method: "PUT",
    };
  },
  services: {
    url: "/dashboard/services",
    baseURL,
  },
  connectService: () => {
    return {
      url: "/services/action/connect",
      baseURL,
    };
  },
  grantPermissionsForService: () => {
    return {
      url: "/services/action/grantPermissions",
      baseURL,
    };
  },
  completeOnboarding: {
    url: "/services/signup/complete",
    baseURL,
    method: "POST",
  },
  disconnectService: (service: string) => {
    return {
      url: `/services/action/disconnect?service=${service}`,
      baseURL,
      method: "post",
      data: {},
    };
  },
  uploadBoxConfiguration: (data: FormData) => {
    return {
      url: "/services/box/configUpload",
      baseURL,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
    };
  },
  cloudSecuritySettings: () => {
    return {
      url: "/settings/cloud-security",
      baseURL,
    };
  },
  thirdPartyApps: () => {
    return {
      url: "/services/thirdPartyApps",
      baseURL,
    };
  },
  toggleThirdPartyAppsStatus: (id: number) => {
    return {
      url: `/services/thirdPartyApps/toggle/${id}`,
      baseURL,
    };
  },
  thirdPartyAppsPermissions: () => {
    return {
      url: `/services/thirdPartyApps/cloudServicePermissions`,
      baseURL,
    };
  },
  restrictions: () => {
    return {
      url: "/restrictions",
      baseURL,
    };
  },
  restrictionsPriorities: () => {
    return {
      url: "/restrictions/priorities",
      baseURL,
    };
  },
  users: () => {
    return {
      url: "/users",
      baseURL,
    };
  },
  searchGroups: (name: string, service?: Service, limit = 10) => {
    const params = {
      name,
      limit,
      service,
    };

    return {
      url: "/groups/search",
      baseURL,
      params,
    };
  },
  completeServiceConnection: () => {
    return {
      url: "/services/action/completeConnection",
      baseURL,
      method: "put",
    };
  },
  completePermissionsGranting: () => {
    return {
      url: "/services/action/completePermissionsGranting",
      baseURL,
      method: "put",
    };
  },
  fetchGoogleClientId: () => ({
    url: "/services/googleClientId",
    baseURL,
  }),
  activateMobile: (mobileToken: string, workspaceId: string) => {
    return {
      url: "/mobile/activate",
      baseURL,
      method: "GET",
      params: { mobileToken },
      headers: {
        Workspace: workspaceId,
      },
    };
  },
  resendMobileActivationLink: (workspaceId: string) => {
    return {
      url: "/mobile/resendInvite",
      baseURL,
      method: "POST",
      headers: {
        Workspace: workspaceId,
      },
    };
  },
  devicesSettings: {
    url: "/settings/devices",
    baseURL,
  },
  devicesAgentVersions: {
    url: "/settings/devices/agentVersions",
    baseURL,
  },
  devicesLabels: {
    url: "/devices/labels",
    baseURL,
  },
  devicesLabel: (id: string) => ({
    url: `/devices/labels/${id}`,
    baseURL,
  }),
  downloadClientPageLink: {
    url: "/settings/devices/downloadClientPageLink",
    baseURL,
  },
  devices: () => {
    return {
      url: "/devices",
      baseURL,
    };
  },
  getDevice: (enrollmentCode: string) => {
    return {
      url: `/devices/${enrollmentCode}`,
      baseURL,
      method: "GET",
    };
  },
  getDeviceTickets: (enrollmentCode: string) => {
    return {
      url: `/devices/${enrollmentCode}/tickets`,
      baseURL,
      method: "GET",
    };
  },
  getDeviceActivityLogs: (enrollmentCode: string) => {
    return {
      url: `/devices/${enrollmentCode}/activityLogs`,
      baseURL,
      method: "GET",
    };
  },
  getDevicesLogs: (deviceId: string) => {
    return {
      url: `/devices/logs/${deviceId}`,
      baseURL,
    };
  },
  devicesAction: {
    url: "/devices/action",
    baseURL,
    method: "POST",
  },
  devicesOsVersions: {
    url: "devices/os/versions",
    baseURL,
  },
  devicesClientVersions: {
    url: "devices/client/versions",
    baseURL,
  },
  devicesStatuses: {
    url: "devices/group/statuses",
    baseURL,
  },
  deviceAdvancedInfo: (enrollmentCode: string) => {
    return {
      url: `/devices/${enrollmentCode}/advancedInfo`,
      baseURL,
    };
  },
  mergeDuplicateDevices: (enrollmentCode: string) => {
    return {
      url: `/mobile/${enrollmentCode}/merge`,
      baseURL,
      method: "POST",
    };
  },
  ignoreDuplicateDevicesMerge: (enrollmentCode: string) => {
    return {
      url: `/mobile/${enrollmentCode}/ignore-merge`,
      baseURL,
      method: "PUT",
    };
  },

  getTerminalSupportedCommands: (enrollmentCode: string) => {
    return {
      url: `/devices/ssh/supportedCommands/${enrollmentCode}`,
      baseURL,
    };
  },

  sendTerminalCommand: (enrollmentCode: string) => {
    return {
      url: `/devices/ssh/${enrollmentCode}`,
      baseURL,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  },
  getSupportedCommands: (enrollmentCode: string) => {
    return {
      url: `/devices/ssh/supportedCommands/${enrollmentCode}`,
      baseURL,
    };
  },
  getLastCommandResults: (commandId: number, receivedAfter: number | null) => {
    const params: { receivedAfter?: number } = {};
    if (receivedAfter) {
      params.receivedAfter = receivedAfter + 1;
    }
    return {
      url: `/devices/ssh/command/${commandId}`,
      baseURL,
      params,
    };
  },
  getSessionStatus: (sessionId: number) => {
    return {
      url: `/devices/ssh/session/${sessionId}`,
      baseURL,
    };
  },
  mspDevices: () => {
    return {
      url: "/msp/devices",
      baseURL,
    };
  },
  getMspDevice: (enrollmentCode: string, workspaceId: string) => {
    return {
      url: `/msp/devices/${enrollmentCode}?workspaceId=${workspaceId}`,
      baseURL,
      method: "GET",
    };
  },
  getMspDeviceTickets: (enrollmentCode: string, workspaceId: string) => {
    return {
      url: `/msp/devices/${enrollmentCode}/tickets?workspaceId=${workspaceId}`,
      baseURL,
      method: "GET",
    };
  },
  getMspDeviceActivityLogs: (enrollmentCode: string, workspaceId: string) => {
    return {
      url: `/msp/devices/${enrollmentCode}/activity-logs?workspaceId=${workspaceId}`,
      baseURL,
      method: "GET",
    };
  },
  mspDeviceLabels: {
    url: "/msp/devices/labels",
    baseURL,
    method: "GET",
  },
  mspDevicesAction: {
    url: "/msp/devices/action",
    baseURL,
    method: "POST",
  },
  mspDevicesOsVersions: {
    url: "/msp/devices/os/versions",
    baseURL,
  },
  mspDevicesClientVersions: {
    url: "/msp/devices/client/versions",
    baseURL,
  },
  mspDevicesStatuses: {
    url: "/msp/devices/group/statuses",
    baseURL,
  },
  mspDeviceAdvancedInfo: (enrollmentCode: string, workspaceId: string) => {
    return {
      url: `/msp/devices/${enrollmentCode}/advanced-info?workspaceId=${workspaceId}`,
      baseURL,
    };
  },
  mspMergeDuplicateDevices: (enrollmentCode: string, workspaceId: string) => {
    return {
      url: `/msp/devices/${enrollmentCode}/merge?workspaceId=${workspaceId}`,
      baseURL,
      method: "POST",
    };
  },
  mspIgnoreDuplicateDevicesMerge: (enrollmentCode: string, workspaceId: string) => {
    return {
      url: `/msp/devices/${enrollmentCode}/ignore-merge?workspaceId=${workspaceId}`,
      baseURL,
      method: "PUT",
    };
  },
  getMspTerminalSupportedCommands: (enrollmentCode: string, workspaceId: string) => {
    return {
      url: `/msp/devices/${enrollmentCode}/ssh/supported-commands?workspaceId=${workspaceId}`,
      baseURL,
    };
  },
  sendMspTerminalCommand: (enrollmentCode: string, workspaceId: string) => {
    return {
      url: `/msp/devices/ssh/${enrollmentCode}?workspaceId=${workspaceId}`,
      baseURL,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  },
  getMspLastCommandResults: (
    commandId: number,
    receivedAfter: number | null,
    workspaceId: string
  ) => {
    const params: { receivedAfter?: number } = {};
    if (receivedAfter) {
      params.receivedAfter = receivedAfter + 1;
    }
    return {
      url: `/msp/devices/ssh/command/${commandId}?workspaceId=${workspaceId}`,
      baseURL,
      params,
    };
  },
  getMspSessionStatus: (sessionId: number, workspaceId: string) => {
    return {
      url: `/msp/devices/ssh/session/${sessionId}?workspaceId=${workspaceId}`,
      baseURL,
    };
  },
  getEdrTelemetryList: (data: GetEdrTelemetryListParams) => {
    return {
      url: "/edr/telemetry",
      baseURL,
      method: "POST",
      data,
    };
  },
  getEdrLog: (id: string) => {
    return {
      url: `/edr/telemetry/${id}/fullLog`,
      baseURL,
    };
  },
  processTelemetryAction: (id: string) => {
    return {
      url: `/edr/telemetry/${id}/action`,
      baseURL,
      method: "post",
    };
  },
  getEdrTelemetryDetails: (id: string) => {
    return {
      url: `/edr/telemetry/${id}`,
      baseURL,
    };
  },
  getEdrProcessList: (params: GetEdrProcessesListParams) => {
    return {
      url: "/edr/processes",
      baseURL,
      params,
    };
  },
  getEdrProcessDetails: (id: string) => {
    return {
      url: `/edr/processes/${id}`,
      baseURL,
    };
  },
  getEdrProcessPaths: (id: string, params: Pagination) => {
    return {
      url: `/edr/processes/${id}/paths`,
      baseURL,
      params,
    };
  },
  getEdrProcessAliases: (id: string, params: Pagination) => {
    return {
      url: `/edr/processes/${id}/aliases`,
      baseURL,
      params,
    };
  },
  getEdrProcessTickets: (id: string) => {
    return {
      url: `/edr/processes/${id}/openTickets`,
      baseURL,
    };
  },
  processEdrAction: () => {
    return {
      url: "/edr/processes/action",
      baseURL,
      method: "post",
    };
  },
  devicesCsvExport: {
    url: "/devices/csv/export",
    baseURL,
    method: "POST",
  },
  mspDevicesCsvExport: {
    url: "/devices/csv/export",
    baseURL,
    method: "POST",
  },
  groupsTotal: {
    url: "/groups/total",
    baseURL,
  },
  exceptions: () => {
    return {
      url: "/users",
      baseURL,
    };
  },
  protectedUsers: (email: string, pagination: Pagination) => {
    return {
      url: "/users",
      baseURL,
      params: {
        isExcepted: false,
        email,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    };
  },
  searchUsers: (email: string, limit = 10) => {
    return {
      url: "/users/cloud/search",
      baseURL,
      params: {
        email,
        limit,
      },
    };
  },
  addUsers: {
    url: "/users",
    baseURL,
    method: "post",
  },
  syncUsers: {
    url: "/users/sync",
    baseURL,
    method: "post",
  },
  removeUser: ({ userId, addToExceptionList }: { userId: string; addToExceptionList: boolean }) => {
    return {
      url: "/users",
      baseURL,
      method: "delete",
      params: {
        userId,
        addToExceptionList,
      },
    };
  },
  addAllUsersToProtection: (service: string) => {
    return {
      url: "/users/addAllToProtection",
      baseURL,
      method: "post",
      data: {
        service,
      },
    };
  },
  protectFromReport: (startTime: number, endTime: number) => {
    return {
      url: `/users/protectFromReport?startTime=${startTime}&endTime=${endTime}`,
      baseURL,
      method: "POST",
    };
  },
  addExceptions: {
    url: "/users/exceptions",
    baseURL,
    method: "post",
  },
  removeException: (userId: string) => ({
    url: "/users/exceptions",
    params: {
      userId,
    },
    baseURL,
    method: "delete",
  }),
  importUsersViaCsv: (data: FormData) => {
    return {
      url: "/users/csv",
      baseURL,
      data,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  },
  getUsersExampleCsv: () => {
    return {
      url: "/users/csv/example",
      baseURL,
      method: "GET",
    };
  },
  groups: (name: string, pagination: Pagination) => {
    return {
      url: "/groups",
      baseURL,
      params: {
        name,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    };
  },
  addGroups: {
    url: "/groups",
    baseURL,
    method: "post",
  },
  removeGroups: {
    url: "/groups",
    baseURL,
    method: "delete",
  },
  exportUsersCsv: {
    url: "/users/csv/export",
    baseURL,
    method: "post",
  },
  mspWorkspaces: () => {
    return {
      url: "/workspaces/msp",
      baseURL,
    };
  },
  mspWorkspace: (id: string) => {
    return {
      url: `/workspaces/msp/${id}`,
      baseURL,
    };
  },
  exportMspWorkspaces: () => {
    return {
      url: "/workspaces/msp/export?type=csv",
      method: "POST",
      baseURL,
    };
  },
  exportMspNotifications: () => {
    return {
      url: "/workspaces/msp/notifications/export?type=csv ",
      method: "POST",
      baseURL,
    };
  },
  exportMspSummary: () => {
    return {
      url: "/workspaces/msp/summary/export",
      method: "POST",
      baseURL,
    };
  },
  convertMspWorkspace: (id: string) => {
    return {
      url: `/workspaces/msp/${id}/type`,
      baseURL,
    };
  },
  archiveWorkspace: (workspaceId: string) => {
    return {
      url: `/workspaces/msp/${workspaceId}/archive`,
      baseURL,
      method: "PUT",
    };
  },
  unarchiveWorkspace: (workspaceId: string) => {
    return {
      url: `/workspaces/msp/${workspaceId}/unarchive`,
      baseURL,
      method: "PUT",
    };
  },
  checkInvitedAdminAlreadyMspAdmin: () => {
    return {
      url: `/admin-users/msp/exists`,
      baseURL,
      method: "POST",
    };
  },
  getRelevantHierarchy: () => {
    return {
      url: `/msp/hierarchy`,
      baseURL,
    };
  },
  getHierarchies: (search: string, page: number, pageSize: number) => {
    return {
      url: `/msp/hierarchy/search`,
      baseURL,
      params: {
        search,
        page,
        pageSize,
      },
    };
  },
  getMspWorkspaceDescendants: () => {
    return {
      url: `/msp/hierarchy/descendants`,
      baseURL,
    };
  },
  workspaceMspCandidates: () => {
    return {
      url: `/workspaces/msp/candidates`,
      baseURL,
      method: "GET",
    };
  },
  bulkEditLabel: () => {
    return {
      url: `/msp/hierarchy/label/assign`,
      baseURL,
    };
  },
  extendWorkspaceTrial: (workspaceId: string) => {
    return {
      url: `/workspaces/${workspaceId}/extendTrial`,
      baseURL,
      method: "PUT",
    };
  },
  editSubscription: () => {
    return {
      url: `/workspaces/msp/subscription`,
      baseURL,
      method: "PUT",
    };
  },
  updateSubscriptionType: () => {
    return {
      url: `/workspaces/subscriptionType`,
      baseURL,
      method: "PUT",
    };
  },
  mspAdminUsers: () => ({
    url: "/admin-users/msp",
    baseURL,
  }),
  mspAdminUser: (id: string) => ({
    url: `/admin-users/msp/${id}`,
    baseURL,
  }),
  mspAdminUsersCandidates: () => ({
    url: `/admin-users/msp/candidates`,
    baseURL,
  }),
  mspAdminUsersWorkspaces: () => ({
    url: `/admin-users/msp/workspaces`,
    baseURL,
  }),
  getAgentGradualUpdateData: ({
    workspacesGroup,
    devicesGroup,
    betaGroupUpdate,
  }: Partial<AgentGradualUpdateData>) => {
    return {
      url: `/gradual-update`,
      baseURL,
      params: {
        workspacesGroup,
        devicesGroup,
        betaGroupUpdate,
      },
    };
  },
  saveAgentGradualUpdateData: (data: Partial<AgentGradualUpdateData>) => {
    return {
      url: `/gradual-update`,
      baseURL,
      method: "PUT",
      data,
    };
  },
  getExportsList: () => {
    return {
      url: "/portal/account/exports-list",
      baseURL,
    };
  },
  exportTicketsToCsv: () => {
    return {
      url: "/events/export/csv",
      baseURL,
      method: "POST",
    };
  },
  mspConversionWorkplaceCandidates: ({
    name,
    page,
    pageSize,
    type,
    channelWorkspaceIdToExclude,
  }: {
    name: string;
    page: number;
    pageSize: number;
    type?: string;
    channelWorkspaceIdToExclude?: string;
  }) => {
    return {
      url: "/workspaces/msp/conversion/parent-candidates",
      baseURL,
      params: {
        name,
        page,
        pageSize,
        type,
        channelWorkspaceIdToExclude,
      },
    };
  },
  getDevicesPostureRegularSettings: {
    url: "/settings/devices/posture/labeled",
    baseURL,
  },
  updateDevicePostureRegularSetting: (id: string) => {
    return {
      url: `/settings/devices/posture/labeled/${id}`,
      baseURL,
      method: "PUT",
    };
  },
  addDevicePostureRegularSetting: () => {
    return {
      url: "/settings/devices/posture/labeled",
      baseURL,
      method: "POST",
    };
  },
  getDeviceInfo: (enrollmentCode: string) => {
    return {
      url: `/devices/${enrollmentCode}`,
      baseURL,
    };
  },
  deleteDevicePostureSetting: (id: string) => {
    return {
      url: `/settings/devices/posture/labeled/${id}`,
      baseURL,
      method: "DELETE",
    };
  },
  updateDevicePostureWifiSetting: (id: string) => {
    return {
      url: `/settings/devices/posture/wifi/${id}`,
      baseURL,
      method: "PUT",
    };
  },
  addDevicePostureWifiSetting: () => {
    return {
      url: "/settings/devices/posture/wifi",
      baseURL,
      method: "POST",
    };
  },
  getDevicesPostureWifiSettings: {
    url: "/settings/devices/posture/wifi",
    baseURL,
  },
  getDevicesPostureUsbLockdownSettings: {
    url: "/settings/devices/posture/usbLockdown",
    baseURL,
  },
  deleteDevicesPostureWifiSettings: (id: string) => {
    return {
      url: `/settings/devices/posture/wifi/${id}`,
      baseURL,
      method: "DELETE",
    };
  },
  updateDevicePostureSensitiveScanSetting: (id: string) => {
    return {
      url: `/settings/devices/posture/dlp/scanSchedule/${id}`,
      baseURL,
      method: "PUT",
    };
  },
  addDevicePostureSensitiveScanSetting: () => {
    return {
      url: "/settings/devices/posture/dlp/scanSchedule",
      baseURL,
      method: "POST",
    };
  },
  deleteDevicesSensitiveScanSettings: (id: string) => {
    return {
      url: `/settings/devices/posture/dlp/scanSchedule/${id}`,
      baseURL,
      method: "DELETE",
    };
  },
  updateDevicePostureUserAccountSetting: (id: string) => {
    return {
      url: `/settings/devices/posture/userAccountPolicy/${id}`,
      baseURL,
      method: "PUT",
    };
  },
  updateDevicePostureUsbLockdownSetting: (id: string) => {
    return {
      url: `/settings/devices/posture/usbLockdown/${id}`,
      baseURL,
      method: "PUT",
    };
  },
  addDevicesPostureUsbLockdownSettings: () => {
    return {
      url: "/settings/devices/posture/usbLockdown",
      baseURL,
      method: "POST",
    };
  },
  addDevicePostureUserAccountSetting: () => {
    return {
      url: "/settings/devices/posture/userAccountPolicy",
      baseURL,
      method: "POST",
    };
  },
  getDevicesPostureUserAccountSettings: {
    url: "/settings/devices/posture/userAccountPolicy",
    baseURL,
  },
  deleteDevicesPostureUserAccountSettings: (id: string) => {
    return {
      url: `/settings/devices/posture/userAccountPolicy/${id}`,
      baseURL,
      method: "DELETE",
    };
  },
  deleteDevicesPostureUsbLockdownSettings: (id: string) => {
    return {
      url: `/settings/devices/posture/usbLockdown/${id}`,
      baseURL,
      method: "DELETE",
    };
  },
  getSwgSettings: {
    baseURL,
    url: "/settings/addons/network/swg",
  },
  sideToSideTunnels: {
    url: "/settings/addons/network/site-to-site-tunnels",
    baseURL,
  },
  siteToSiteTunnel: (id: string | number) => {
    return {
      url: `/settings/addons/network/site-to-site-tunnels/${id}`,
      baseURL,
    };
  },
  testSideToSideTunnels: (id: string | number) => {
    return {
      url: `/settings/addons/network/site-to-site-tunnels/${id}/test`,
      baseURL,
      method: "POST",
    };
  },

  validateSubnetRange: (data: string[]) => ({
    url: `/settings/addons/network/site-to-site-tunnels/validate`,
    baseURL,
    method: "POST",
    data,
  }),

  toggleSiteToSiteTunnel: (id: string | number, enable: boolean) => {
    return {
      url: `/settings/addons/network/site-to-site-tunnels/${id}/toggle`,
      baseURL,
      method: "PUT",
      params: {
        enable,
      },
    };
  },
  downloadTunnelLogs: (tunnelId: string | number) => {
    return {
      url: `/settings/addons/network/site-to-site-tunnels/${tunnelId}/download`,
      baseURL,
      method: "GET",
    };
  },
  rocSettings: {
    url: "/settings/addons/network/site-to-site-tunnels/rocs",
    baseURL,
  },
  vpnSetup: {
    url: "/settings/addons/network/vpn/setup",
    baseURL,
  },
  vpnRegions: {
    url: "/settings/addons/network/vpn/regions",
    baseURL,
  },
  setupVirtualOffice: (data: { region: string }) => {
    return {
      url: "/settings/addons/network/vpn/setup",
      baseURL,
      method: "POST",
      data,
    };
  },
  networkSettings: () => {
    return { url: `/settings/addons/network/vpn/settings`, baseURL };
  },
  getVpnSettings: () => ({
    url: "/settings/addons/network/vpn",
    baseURL,
    method: "GET",
  }),
  getMobileBannerConfiguration: () => ({
    url: "/settings/addons/network/vpn/mobile-banner",
    baseURL,
    method: "GET",
  }),
  updateMobileBannerConfiguration: (data: { visible: boolean }) => ({
    url: "/settings/addons/network/vpn/mobile-banner",
    baseURL,
    method: "PUT",
    data,
  }),
  getVpnUrlList: ({
    page,
    pageSize,
    search,
    excluded,
  }: {
    page: number;
    pageSize: number;
    search?: string | null;
    excluded?: boolean;
  }) => ({
    url: "/settings/addons/network/vpn/urls",
    baseURL,
    method: "GET",
    params: {
      page,
      pageSize,
      search,
      excluded,
    },
  }),
  removeFromVpnUrlList: (data: { urlName: string; excluded: boolean }) => ({
    url: "/settings/addons/network/vpn/urls",
    baseURL,
    method: "DELETE",
    data,
  }),
  addToVpnUrlList: (data: { items: string[]; excluded: boolean }) => ({
    url: "/settings/addons/network/vpn/urls",
    baseURL,
    method: "POST",
    data,
  }),
  updateVpnSettings: (data: VirtualOfficeSettings) => ({
    url: "/settings/addons/network/vpn",
    baseURL,
    method: "PUT",
    data,
  }),
  swgUrlList: () => ({
    url: "/settings/addons/network/swg/urls",
    baseURL,
  }),
  importToSwgUrlList: (data: FormData) => ({
    url: "/settings/addons/network/swg/urls-file",
    baseURL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    method: "POST",
    data,
  }),
  swgUrlListItem: (id: string) => ({
    url: `/settings/addons/network/swg/urls/${id}`,
    baseURL,
  }),
  updateSwgSettings: (data: SecureWebGatewaySettings) => ({
    url: "/settings/addons/network/swg",
    baseURL,
    method: "PUT",
    data,
  }),
  getSwgExampleFile: () => ({
    url: "/settings/addons/network/swg/urls/example",
    baseURL,
  }),
  viewUrlList: (id: string) => {
    return {
      url: `/settings/addons/network/swg/urls/${id}/url-list`,
      baseURL,
      method: "GET",
    };
  },
  getTelemetryRecentSearches: {
    url: "/edr/telemetry/recentSearches",
    baseURL,
  },
  clearTelemetryRecentSearches: {
    url: "/edr/telemetry/recentSearches",
    method: "DELETE",
    baseURL,
  },
  scheduledReports: () => ({
    url: "/scheduled-reports",
    baseURL,
  }),
  scheduledReport: (id: string) => ({
    url: `/scheduled-reports/${id}`,
    baseURL,
  }),
  billingStats: () => ({
    baseURL,
    url: "/workspaces/billing/stats",
  }),
  updateBillingSubscription: () => ({
    baseURL,
    url: "/workspaces/billing/update-subscription",
    method: "PUT",
  }),
  messages: {
    url: "/messages",
    baseURL,
  },
  dismissMessage: (id: string) => {
    return {
      url: "/messages/dismiss",
      baseURL,
      method: "POST",
      params: {
        id,
      },
    };
  },
  forgotPassword: (email: string, brandingAlias?: string) => {
    return {
      url: "/forgotPassword",
      baseURL,
      params: {
        email,
        brandingAlias,
      },
    };
  },
  resetPassword: {
    url: "/resetPassword",
    baseURL,
    method: "POST",
  },
  mdmUrl: {
    url: "/mdm/url",
    baseURL,
  },
  reportPhishingImpersonation: () => {
    return {
      url: "/settings/phishing/report/impersonation",
      baseURL,
      method: "POST",
    };
  },
  verifyMFACode: {
    url: "/mfa/verify-code",
    baseURL,
    method: "POST",
  },
  mfaConfig: {
    url: "/portal/account/auth/mfa",
    baseURL,
    method: "POST",
  },
  completeMFA: (verificationCode: string) => {
    return {
      url: "/portal/account/auth/mfa/complete",
      baseURL,
      params: {
        verificationCode,
      },
      method: "POST",
    };
  },
  addMFAFallback: {
    url: "/portal/account/auth/mfa/fallback",
    baseURL,
    method: "POST",
  },
  getMFAFallback: {
    url: "/portal/account/auth/mfa/fallback",
    baseURL,
    method: "GET",
  },
  completeMFAReset: {
    url: "/mfa/sms-code/complete",
    baseURL,
    method: "POST",
  },
  completeMFAFallback: {
    url: "/portal/account/auth/mfa/fallback/complete",
    baseURL,
    method: "POST",
  },
  resendSMS: {
    url: "/mfa/sms-code/resend",
    baseURL,
    method: "POST",
  },
  demoMode: {
    url: "/demo-mode",
    baseURL,
  },
  resetDemoModeData: {
    url: "/demo-mode/reset",
    baseURL,
    method: "POST",
  },
  loginViaSSO: (token: string) => {
    return {
      url: "/auth/transfer",
      baseURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  },
  contactUser: {
    url: "/users/contact",
    baseURL,
    method: "post",
  },
  updateSocStatus: (eventId: string) => {
    return {
      url: `/soc/tickets/${eventId}/socStatus`,
      baseURL,
      method: "PUT",
    };
  },
  searchSocWorkspaces: ({ search, page, pageSize }: Pagination & { search: string }) => {
    return {
      url: "/soc/tickets/workspaces",
      baseURL,
      method: "GET",
      params: {
        search,
        page,
        pageSize,
      },
    };
  },
  getSocTickets: () => {
    return {
      url: "/soc/tickets",
      baseURL,
    };
  },
  socTicket: (eventId: string) => {
    return {
      url: `/soc/tickets/${eventId}`,
      baseURL,
    };
  },
  socTicketAction: (eventId: string) => {
    return {
      url: `/soc/tickets/${eventId}/action`,
      baseURL,
      method: "post",
    };
  },
  getTickets: () => {
    return {
      url: "/events",
      baseURL,
    };
  },
  ticket: (eventId: string) => ({
    url: `/events/${eventId}`,
    baseURL,
  }),
  ticketAction: (eventId: string) => ({
    url: `/events/${eventId}/action`,
    baseURL,
    method: "post",
  }),
  ticketBulkAction: () => {
    return {
      url: "/events/action",
      baseURL,
      method: "post",
    };
  },
  getTicketActivityLogs: (eventId: string) => {
    return {
      url: `/events/${eventId}/activityLogs`,
      baseURL,
      method: "GET",
    };
  },
  getTicketRecipients: (eventId: string) => {
    return {
      url: `/events/${eventId}/recipients`,
      baseURL,
      method: "GET",
    };
  },
  socTicketBulkAction: () => {
    return {
      url: "/soc/tickets/bulkAction",
      baseURL,
      method: "post",
    };
  },
  ticketDownloadAction: (eventId: string) => {
    return {
      url: `/events/${eventId}/action/download`,
      baseURL,
      method: "post",
    };
  },
  edrAffectedDevices: (eventId: string, { page, pageSize }: Pagination) => {
    return {
      url: `/events/edr/${eventId}/affectedDevices`,
      baseURL,
      params: {
        page,
        pageSize,
      },
    };
  },
  abnormalDataManipulationFiles: ({
    eventId,
    page,
    pageSize,
    folderId,
  }: Pagination & { eventId: string; folderId: string }) => {
    return {
      url: `/events/abnormalDataManipulation/${eventId}/${folderId}/files`,
      baseURL,
      params: {
        page,
        pageSize,
      },
    };
  },
  findings: ({ eventId, page, pageSize }: Pagination & { eventId: string }) => {
    return {
      url: `/events/${eventId}/findings`,
      baseURL,
      params: {
        page,
        pageSize,
      },
    };
  },
  abnormalDataManipulationFolders: ({
    eventId,
    page,
    pageSize,
  }: Pagination & { eventId: string }) => {
    return {
      url: `/events/abnormalDataManipulation/${eventId}/folders`,
      baseURL,
      params: {
        page,
        pageSize,
      },
    };
  },
  coroAdminEmails: {
    url: "/admin-users/global/emails",
    baseURL,
    method: "GET",
  },
  socCommentAction: (commentId: string) => {
    return {
      url: `/soc/tickets/comments/${commentId}/action`,
      baseURL,
      method: "POST",
    };
  },
  addSocBulkComment: {
    url: "/soc/tickets/comments",
    baseURL,
    method: "POST",
  },
  toggleZtnaEnabled: (enable: boolean) => ({
    baseURL,
    url: "/settings/addons/network/ztna/toggle",
    method: "POST",
    params: { enable },
  }),
  ztnaRules: (id?: string) => {
    return {
      url: `/settings/addons/network/ztna/resources${id ? "/" + id : ""}`,
      baseURL,
    };
  },
  ztnaStatus: () => {
    return {
      url: "/settings/addons/network/ztna/status",
      baseURL,
    };
  },
  getProcessGraph: (params: GetProcessGraphParams) => {
    return {
      url: "/edr/process-tree",
      baseURL,
      params,
    };
  },
  getProcessGraphDetails(id: string) {
    return {
      url: `/edr/process-tree/${id}`,
      baseURL,
    };
  },
  processGraphAction(id: string, ticketId: string, action: ProcessGraphAction) {
    return {
      url: `/edr/process-tree/${id}/action`,
      method: "POST",
      baseURL,
      data: {
        action,
        ticketId,
      },
    };
  },
  getBranding: (brandingAlias: string) => {
    return {
      url: `/branding/${brandingAlias}`,
      baseURL,
    };
  },
  getMspUsers: () => {
    return {
      url: "/msp/users",
      baseURL,
      method: "POST",
    };
  },
  getMspUserInfo: (userId: string) => {
    return {
      url: `/msp/users/${userId}`,
      baseURL,
      method: "GET",
    };
  },
  getMspUserTickets: (userId: string) => {
    return {
      url: `/msp/users/${userId}/tickets`,
      baseURL,
      method: "GET",
    };
  },
  getMspUserActivityLogs: (userId: string) => {
    return {
      url: `/msp/users/${userId}/activityLogs`,
      baseURL,
      method: "GET",
    };
  },
  mspUserAction: {
    url: "/msp/users/action",
    baseURL,
    method: "POST",
  },
  mspUsersAvailableActions: {
    url: "/msp/users/available/action",
    baseURL,
    method: "POST",
  },
  getMspTickets: () => {
    return {
      url: "/msp/tickets",
      baseURL,
    };
  },
  mspTicket: (eventId: string) => ({
    url: `/msp/tickets/${eventId}`,
    baseURL,
  }),
  mspTicketAction: (eventId: string) => ({
    url: `/msp/tickets/${eventId}/action`,
    baseURL,
    method: "POST",
  }),
  mspTicketBulkAction: () => {
    return {
      url: "/msp/tickets/action",
      baseURL,
      method: "POST",
    };
  },
  getMspTicketActivityLogs: (eventId: string) => {
    return {
      url: `/msp/tickets/${eventId}/activity-logs`,
      baseURL,
      method: "GET",
    };
  },
  getMspTicketRecipients: (eventId: string) => {
    return {
      url: `/msp/tickets/${eventId}/recipients`,
      baseURL,
      method: "GET",
    };
  },
  mspTicketDownloadAction: (eventId: string) => {
    return {
      url: `/msp/tickets/${eventId}/action/download`,
      baseURL,
      method: "POST",
    };
  },
  mspTicketEdrAffectedDevices: (eventId: string) => {
    return {
      url: `/msp/tickets/edr/${eventId}/affectedDevices`,
      baseURL,
    };
  },
  mspTicketAbnormalDataManipulationFiles: ({
    eventId,
    folderId,
  }: {
    eventId: string;
    folderId: string;
  }) => {
    return {
      url: `/msp/tickets/abnormalDataManipulation/${eventId}/${folderId}/files`,
      baseURL,
    };
  },
  mspTicketFindings: (eventId: string) => {
    return {
      url: `/msp/tickets/${eventId}/findings`,
      baseURL,
    };
  },
  mspTicketAbnormalDataManipulationFolders: (eventId: string) => {
    return {
      url: `/msp/tickets/abnormalDataManipulation/${eventId}/folders`,
      baseURL,
    };
  },
  getMspTicketComments: (eventId: string) => {
    return {
      url: `/msp/tickets/${eventId}/comments`,
      baseURL,
      method: "GET",
    };
  },
  exportMspTicketsToCsv: () => {
    return {
      url: "/msp/tickets/export/csv",
      baseURL,
      method: "POST",
    };
  },
  getMspDrives: () => {
    return {
      url: "/msp/drives",
      baseURL,
    };
  },
  mspDrivesAction: {
    url: "/msp/drives/action",
    baseURL,
    method: "POST",
  },
  exportMspDevicesToCsv: () => {
    return {
      url: "/msp/devices/export/csv",
      baseURL,
      method: "POST",
    };
  },
  exportMspUsersToCsv: () => {
    return {
      url: "/msp/users/export/csv",
      baseURL,
      method: "POST",
    };
  },
  searchMspWorkspaceLabels: (search: string = "") => {
    return {
      url: `/msp/hierarchy/label/search`,
      baseURL,
      method: "GET",
      params: {
        search,
      },
    };
  },
  mspWorkspaceLabels: {
    url: "/msp/hierarchy/label",
    baseURL,
  },
  mspWorkspaceLabel: (id: string) => ({
    url: `/msp/hierarchy/label/${id}`,
    baseURL,
  }),
  mspWorkspaceDetails: (id: string) => ({
    url: `/workspaces/msp/${id}/details`,
    baseURL,
  }),
  availableMspTicketBulkActions: {
    url: "/msp/tickets/available-actions",
    baseURL,
    method: "POST",
  },
  verifyPublicEmail: (email: string) => ({
    url: `/sign-up/verify-email`,
    baseURL,
    method: "POST",
    data: {
      email,
    },
  }),
  requestSignUpVerificationCode: () => ({
    url: `/sign-up/request-verification-code`,
    baseURL,
  }),
  completeSignUp: () => ({
    url: `/sign-up/complete`,
    baseURL,
  }),
  startTrialForCurrentWorkspace: () => ({
    url: `/workspaces/current/start-trial`,
    baseURL,
    method: "POST",
  }),
  resetTrialNotice: () => ({
    url: `/workspaces/current/reset-trial-notice`,
    baseURL,
    method: "POST",
  }),
  mspDashboardGeneratedTickets: () => ({
    url: `/msp/dashboard/tickets/generated-stats`,
    baseURL,
  }),
  mspDashboardModuleTickets: () => ({
    url: `/msp/dashboard/tickets/module-stats`,
    baseURL,
  }),
  mspDashboardWorkspaceTickets: () => ({
    url: `/msp/dashboard/tickets/workspace-stats`,
    baseURL,
  }),
  mspDashboardUsersStats: () => ({
    url: `/msp/dashboard/users/stats`,
    baseURL,
  }),
  mspDashboardDevicesStats: () => ({
    url: `/msp/dashboard/devices/stats`,
    baseURL,
  }),
  mspDashboardWorkspacesStats: () => ({
    url: `/msp/dashboard/workspaces/stats`,
    baseURL,
  }),
} as const satisfies Record<string, AxiosRequestConfig | ((...args: any[]) => AxiosRequestConfig)>;
